@import 'styles/_sass-includes.scss';

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
