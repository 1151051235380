.list--aside {
  border-left: 2px solid $dark;
  padding-left: 30px;
  // media queries
  @include breakpoint($mobile-lg) {
    padding-left: 20px;
  }

  > li {
    @include font(bold);
    @include font-size(b2);
    margin-bottom: 30px;
    // media queries
    @include breakpoint($mobile-lg) {
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
