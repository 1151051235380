@import 'styles/_sass-includes.scss';

.ourvalues {
  z-index: 1;
  background-color: transparent;
  width: 100%;
  // media queries
  @include breakpoint($tablet-lg) {
    height: auto;
    position: relative;
    min-height: 0;
  }
  @include breakpoint($mobile-lg) {
    padding-bottom: 0;
  }

  .flickity-viewport {
    overflow: visible;
    z-index: 10;
  }

  .carousel__slide {
    opacity: 0;
    transition: 0.2s ease-in-out;

    &.is-selected {
      opacity: 1;
    }
  }

  .scrollindicator {
    position: relative;
    display: block;
    margin-top: 120px;
    text-align: center;
  }

  .carousel__slide.is-selected h4 {
    color: $primary;
  }

  .carousel.show-dots {
    padding-bottom: 20px;
  }
}


.ourvalues__slide {
  position: relative;
  display: flex;
  align-items: flex-start;

  span {
    position: absolute;
    left: 0;
    top: 130px;
    z-index: 1;
  }

  > div {
    width: 50%;
  }
}

.ourvalues__title {
  position: relative;
  padding-bottom: 50%;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ourvalues__circle {
  background-color: $primary;
  border-radius: 50%;
  width: 134px;
  height: 134px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 100px;
    height: 100px;
  }
  @include breakpoint($mobile-lg) {
    width: 80px;
    height: 80px;
  }
}

.ourvalues__sections {
  display: flex;
  // media queries
  @include breakpoint($tablet-lg) {
    flex-direction: column;
  }
}

.ourvalues__imgcontainer {
  width: 33.333%;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 350px;
    margin-bottom: 30px;
  }
  @include breakpoint($mobile-lg) {
    width: 100%;
  }
}

.ourvalues__hero {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.ourvalues__img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  // media queries
  @include breakpoint($tablet-lg) {
    position: relative;
    opacity: 1;
    transform: none;
    max-width: 550px;
  }

  &.is-active {
    opacity: 1;
    transform: none;
  }
}

.ourvalues__grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 100px;
  // media queries
  @include breakpoint($tablet-lg) {
    margin-left: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  > div {
    cursor: pointer;
    width: calc(50% - 40px);
    margin-right: 20px;
    margin-bottom: 10px;
    min-height: 140px;
    // media queries
    @include breakpoint($desktop-sm) {
      width: 100%;
      margin-right: 0;
      min-height: 0;
    }
    @include breakpoint($tablet-lg) {
      width: 300px;
      flex-shrink: 0;
    }
  }

  h4 {
    transition: color 0.2s ease-in-out;
  }
}
