@import '~styles/_sass-includes.scss';

.circles__new {
  position: absolute;
  pointer-events: none;
  width: 1440px;
  height: 1640px;
  // top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  display: block;
  // media queries
  @include breakpoint($tablet-lg) {
  }
  @include breakpoint($mobile-lg) {
  }
  @include breakpoint($mobile-xsm) {
  }
}

.circles__item {
  position: absolute;
  background-color: $lavender;
  border-radius: 50%;

  &:nth-of-type(1) { background-color: $beige; }
  &:nth-of-type(2) { background-color: $babyblue; }
  &:nth-of-type(3) { background-color: $grey; }
  &:nth-of-type(4) { background-color: $greyblue; }
  &:nth-of-type(5) { background-color: $lavender; }
  &:nth-of-type(6) { background-color: $medBlue; }
  &:nth-of-type(7) { background-color: $lightBlue; }
  &:nth-of-type(8) { background-color: $tan; }
  &:nth-of-type(9) { background-color: $medBlue; }

  > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  img {
    max-width: none;
    width: 100%;
    object-fit: contain;
  }
}

.circles__carousel {
  border-radius: 50%;
  overflow: hidden;
  z-index: 10;
  position: relative;
}

.circles__caption {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  pointer-events: none;
  overflow: visible;

  path { fill: transparent; }

  text {
    @include font(bold);
    @include font-size(b2);
  }

  &.circle--left {
    transform: translate(-50%, -50%) rotate(-110deg);
  }

  @include breakpoint($tablet-sm) {
    width: calc(100% + 30px);
    height: calc(100% + 30px);

    text {
      @include font-size(b3);
    }

  }
}
