// default global body styles
body {
  color: $dark;
  background-color: $white;
}

// default global link styles
a,
.typ--link {
  color: $primary;
  transition: color 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $dark;
  }
}

.typ--primary { color: $primary; }
.typ--dark { color: $dark; }
.typ--black { color: $black; }
.typ--white { color: $white; }
.typ--beige { color: $beige; }
.typ--babyblue { color: $babyblue; }
.typ--grey { color: $grey; }
.typ--greyblue { color: $greyblue; }
.typ--offwhite { color: $offwhite; }

.bg--primary { background-color: $primary; }
.bg--dark { background-color: $dark; }
.bg--black { background-color: $black; }
.bg--white { background-color: $white; }
.bg--beige { background-color: $beige; }
.bg--babyblue { background-color: $babyblue; }
.bg--grey { background-color: $grey; }
.bg--greyblue { background-color: $greyblue; }
.bg--offwhite { background-color: $offwhite; }

// BG colors for team photos
.bg-tan { background-color: $tan; }
.bg-light-blue { background-color: $lightBlue; }
.bg-purple { background-color: $lavender; }
.bg-medium-blue { background-color: $medBlue; }

.theme--dark { @extend %theme--dark; }

.sectiontitle {
  border-top: 2px solid $dark;
  padding-top: 0.4em;
  margin-bottom: 1em;
}

.numberedtitle {
  @include font(bold);
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-left: -64px;
  position: relative;
  // media queries
  @include breakpoint($tablet-md) {
    margin-left: 0;
  }

  span {
    @include font-size(b2);
    width: 64px;
    // media queries
    @include breakpoint($tablet-md) {
      display: none;
    }
  }
}

.circle__shadow {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba($primary, 0);
  background-color: $primary;
  border-radius: 50%;
  // transition: opacity 0.1s ease-in-out, height 0.1s ease-in-out, width 0.1s ease-in-out;
}

.scrollindicator {
  @include font-size(h2);
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 11;
}

.modal__close {
  position: fixed;
  transform : translate3d(0, 0, 0);
  top: -25px;
  right: -25px;
  width: 80px;
  height: 80px;
  background-color: $primary;
  color: $white;
  z-index: 95;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: $dark;
  }

  [class^="icon-"] {
    font-size: 22px;
    padding-left: 22px;
    padding-bottom: 22px;
  }
}
