.btn {
  @include font(bold);
  @include font-size(b1);
  color: $primary;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  padding: 10px 10px 10px 0;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: left 0.2s ease-in-out, color 0.2s ease-in-out;

  &:before {
    content: '';
    width: 35px;
    height: 2px;
    background-color: $primary;
    margin-right: 17px;
    display: block;
    top: 2px;
    position: relative;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  &:hover {
    color: $dark;
    left: -15px;

    &:before {
      background-color: $dark;
      width: 50px;
    }
  }
}
