// primary color
$primary: #FF626F;
$primary-light: #ffdddf;
$primary-extralight: #fffafa;

// ancillary colors
$dark: #001928;
$lightDark: #9ca6ab;
$white: #FFFFFF;
$black: #000000;
$offwhite: #FBFBFB;

// pastels
$beige: #F1D6BB;
$babyblue: #D7FFFF;
$grey: #DDDCD8;
$greyblue: #B8E3EC;
$lavender: #E8E0F0;
$medBlue: #C1DDF2;
$lightBlue: #C1F1F9;
$tan: #E5DCCC;
