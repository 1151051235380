@import 'styles/_sass-includes.scss';

.member {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $white;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.member__bio {
  padding-top: 40vh;
  height: 100vh;
  position: relative;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 28vh;
  }
  @include breakpoint($tablet-sm) {
    padding-top: 430px;
  }
}

.member__name {
  max-width: 500px;
  // margin-left: -100px;
  margin-bottom: 90px;
  z-index: 120;
  position: relative;
  // media queries
  @include breakpoint($tablet-sm) {
    margin-left: 0;
    margin-bottom: 50px;
  }
}

.member__content {
  @include font-size(h5);
  @include font(light);
  // media queries
  @include breakpoint($tablet-sm)  {
    @include font-size(b1);
  }

  .lang-jp & {
    font-size: 18px;
    line-height: 1.8;
  }

  p {
    margin-bottom: 50px;
    // media queries
    @include breakpoint($tablet-sm) {
      margin-bottom: 30px;
    }
  }

}

.member__nameline {
  height: 2px;
  width: 100%;
  background-color: $dark;
  margin-bottom: 20px;
  display: block;
}

.member__img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  position: relative;
  pointer-events: none;
  // media queries
  @include breakpoint($tablet-sm) {
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -665px;
    width: 1045px;
    height: 1045px;
  }
}

.member__imgwindow {
  position: fixed;
  top: 50%;
  width: 4000px;
  height: 4000px;
  transform: translateY(-50%);
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
  -webkit-clip-path: circle(50%);

  // media queries
  @include breakpoint($tablet-sm) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    width: 95vw;
    position: absolute;
    right: -15vw;
    top: 50%;
    transform: translateY(-50%);
    // media queries
    @include breakpoint($desktop-sm) {
      right: -25vw;
    }
    @include breakpoint($tablet-sm) {
      width: 540px;
      right: auto;
      bottom: -50px;
      top: auto;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}

*::-ms-backdrop,
.member__imgwindow {
  width: 45vw;
  height: 100vh;
  transform: none;
  top: 0;
  left: 0;
  right: auto;
  border-radius: 0;
  // media queries
  @include breakpoint($desktop-sm) {
    width: 32vw;
  }
} /* IE11 */

*::-ms-backdrop,
.member__imgwindow img {
  width: 800px;
  max-width: none;
  left: 50%;
  right: auto;
  top: auto;
  bottom: 0;
  transform: translateX(-50%);
  // media queries
  @include breakpoint($desktop-sm) {
    width: 600px;
  }
}

*::-ms-backdrop,
.member__name {
  margin-left: 0;
}

.member__social {
  display: flex;

  li a {
    @include font-size(h5);
    margin-right: 30px;
    color: $dark;

    &:hover {
      color: $primary;
    }
  }
}

.member__fact {
  position: relative;
  // media queries
  @include breakpoint($tablet-sm) {
    padding-left: 60px;
  }

  [class^="icon-"] {
    color: $primary;
    font-size: 40px;
    // media queries
    @include breakpoint($tablet-sm) {
      position: absolute;
      left: 0;
    }
  }
}

.member__next {
  background-color: $offwhite;
  position: relative;
  z-index: 5;

  a {
    height: 144px;
    color: $dark;
    display: flex;
    align-items: center;
    width: 100%;
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 144px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $offwhite;
    z-index: -1;
  }
}

.member__companies {

  span {
    display: inline-block;
  }
}

.company-link {
  @include font(bold);
  color: $black;
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.member__relatedinsights {
  ul {
    margin-top: 2rem;
  }

  .insight__item {
    border-top: none;
    padding-top: 1rem;
    padding-bottom: 1rem;

    button {
      font-family: inherit;
      color: $dark;
      font-weight: 300;
    }

    a {
      color: $dark;
      font-weight: 300;
    }

    .insight__title {
      line-height: 1.5em;
      margin-bottom: 0em;
      text-align: left;
      transition: 0.2s;

      &:hover {
        color: $primary;
      }
    }
  }

}
