@import 'styles/_sass-includes.scss';

.principles__section__wrapper {
  display: flex;
  align-items: center;
  @include breakpoint($mobile-lg){
    display: block;
  }
}

.principles__texts__wrapper {
  position: relative;
}

.principles__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 120px;
  height: 120px;
  z-index: -1;
  position: absolute;
  top: 0%;
  transform: translate(-50%, -32%);

  // media queries
  @include breakpoint($tablet-lg) {
    position: absolute;
    width: 100px;
    height: 100px;
  }
  @include breakpoint($tablet-md) {
    width: 80px;
    height: 80px;
  }

}

.principles__image{
  width: 100%;

  @include breakpoint($mobile-lg) {
    width: 70%;
  }
  @include breakpoint($mobile-md) {
    width: 80%;
  }
  @include breakpoint($mobile-sm) {
    width: 90%;
  }

}

.principles__image__box {
  width: 100%;
  text-align: center;
}