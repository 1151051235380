@import '~styles/_sass-includes.scss';

.locations {
  background-color: $white;
  width: 100%;

  > .row {
    height: calc(100vh - 470px); 
    overflow: hidden;
    min-height: 820px;
    z-index: 1;
    position: relative;
    // media queries
    @include breakpoint($tablet-sm) {
      height: auto;
    }
  }

  .footer {
    margin-top: -40px;
  }
}

.locations__map {
  position: absolute;
  right: 0;
  display: flex;
  height: 100vh;
  width: 100vh;
  max-height: 1156px;
  max-width: 1156px;
  pointer-events: none;
  z-index: 1;
  // media queries
  @include breakpoint($tablet-lg) {
    height: 75vh;
    width: 75vh;
    top: 130px;
  }
  @include breakpoint($tablet-sm) {
    width: 60vh;
    height: 60vh;
    top: 0;
    position: relative;
  }
  @include breakpoint($mobile-lg) {
    width: 50vh;
    height: 50vh;
  }

  img {
    position: absolute;
    transition: opacity 0.3s ease-in-out,
                transform 0.3s ease-in-out;

    &.fade-in {
      transform: none;
      opacity: 1;
    }

    &.fade-out {
      transform: translateX(-400px);
      opacity: 0;
    }
  }
}

.locations__circle {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $primary;
  border-radius: 50%;
  z-index: 1000;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 12px;
    height: 12px;
  }
}

.locations__address p {
  // media queries
  @include breakpoint($mobile-xsm) {
    @include font-size(b2);
  }

  span {
    display: block;
    margin-bottom: 5px;
  }
}