@import 'styles/_sass-includes.scss';

.mobilenav {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  // background-image: url('../../assets/img/mobilenav_bg.jpg');
  background-color: white;
  background-position: center bottom;
  background-size: cover;
  z-index: 2000;

  li {
    @include font-size(h3);
    padding: 10px 0 10px;
    text-align: center;
    display: block;
    // media queries
    @include breakpoint($tablet-lg) {
      @include font-size(h4);
      padding: 8px 0 8px;
    }

    a {
      color: $white;
    }

    &.is-active:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      background-color: $primary;
      border-radius: 50%;
    }
  }
}

.mobilenav__header {
  z-index: 3;
  color: $white;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }
}

.mobilenav__top {
  height: 70vh;
  min-height: 400px;
  // background-color: $dark;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    background-image: url('../../assets/img/mobilenav_mask.svg');
    width: 1657px;
    height: 1657px;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  ul {
    margin-top: -25vh;
    position: relative;
    z-index: 5;
  }
}

.mobilenav__footer {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 2;
}
