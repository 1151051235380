@import '~styles/_sass-includes.scss';

.companies {
  background-color: $white;
}

.companies__list {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    padding-right: 50px;
    margin-bottom: 30px;
    // media queries
    @include breakpoint($desktop-sm) {
      width: 100%;
    }
    @include breakpoint($mobile-md) {
      width: 280px;
    }
  }
}

.companies__scroller {
  @extend %full-bleed;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.partnergrid, .venture-funds--text {
  width: 100%;
  padding-left: 50px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-left: 0;
  }
}

// Because of .clientgrid 160px margin-bottom
.venture-funds {
  // margin-top: -100px;
  
  .lang-jp & h2.typ--jp {
    font-size: 24px;
    line-height: 1.4;
  }
}

.venture-funds--text {
  padding-right: 60px;
}

.partnergrid__container {
  display: flex;
  flex-wrap: wrap;
}

.partnergrid__item {
  width: 50%;
  padding-right: 60px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-right: 40px;
  }
  @include breakpoint($mobile-lg) {
    width: 100%;
    padding-right: 0;
  }

  a {
    color: $dark;
  }

  &:not(.has-link) a {
    cursor: default;
  }

  &.has-link:hover p.typ--bold {
    color: $primary;
  }

  .lang-jp & .typ--light {
    display: none;
  }
}

.partnergrid__section {
  margin-bottom: 40px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 20px;
  }
}

.partnergrid__section__header {
  border-top: 1px solid $dark;
  padding-top: 20px;

  display: flex;
  align-items: center;

  .partnergrid__industries__icon {
    width: 48px;
  }

  img {
    margin-right: 16px;
  }
}

.clientgrid {
  .lang-jp & h2.typ--jp {
    font-size: 24px;
    line-height: 1.4;
  }
}

.companies__anchorLink {
  @include font(regular);
  line-height: 1;
  color: $primary;
  text-align: left;
  // -webkit-font-smoothing: antialiased;
  &:hover {
    color: $dark;
  }
}

.companies__anchor__navigation {
  margin-bottom: 36px;
}

.partnergrid__item {
  img {
    max-width: 70%;
  }
}

.companies__disclaimer {
  width: 80%;
  margin: 0 auto;
  line-height: 1em;
  font-size: 0.8em;
  color: $lightDark;

  @include breakpoint($tablet-lg) {
    width: 90%;
  }

  a {
  font-size: 0.8em;
  color: $lightDark;
  &:hover {
    color: $dark;
    }
  }
}