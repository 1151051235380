@import 'styles/_sass-includes.scss';

.companiescarousel {
  .flickity-viewport {
    overflow: visible;
  }

  .carousel__slide {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;

    &.is-selected {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.4s ease-in-out;

      .companiescarousel__slidetag {
        transform: none;
      }

      .companiescarousel__slidelogo {
        opacity: 1;
        transform: translate(-50%, -50%);
        width: auto;
      }
    }
  }
}

.companiescarousel__slidetag {
  transform: translateX(100px);
  position: absolute;
  right: 8%;
  top: 10%;
  z-index: 10;
  transition: transform 0.6s ease-in-out 0.1s,
              color 0.4s ease-in-out;
}

.companiescarousel__tagline {
  position: absolute;
  z-index: 10;
  // media queries
  @include breakpoint($tablet-lg) {
    position: relative;
  }

  .lang-jp & h1.typ--jp {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600;
  }

  .lang-jp & h1.typ--jp.typ--h2 {
    font-size: 28px;
    line-height: 1.5;
  }

}

.companiescarousel__image {
  position: relative;
  padding-bottom: 48.386%;
  background-color: $lavender;
  border-radius: 50%;
  overflow: hidden;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-bottom: 73.784%;
  }
  @include breakpoint($mobile-lg) {
    padding-bottom: 100%;
  }
  @include breakpoint($tablet-lg, min-width) {
    &:hover {
      .companiescarousel__quote {
        opacity: 1;
        pointer-events: auto;
  
        > div {
          transform: none;
          opacity: 1;
        }
      }
  
      > span {
        color: $primary;
      }
    }
  }
}

.companiescarousel__slideimg {
  display: block;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.companiescarousel__slidelogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + 20px), -50%);
  opacity: 0;
  transition: transform 0.7s ease-in-out 0.2s,
              opacity 0.7s ease-in-out 0.2s;
}

.companiescarousel__quote {
  position: absolute;
  background-color: $white;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 125px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  // media queries
  @include breakpoint($desktop-lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include breakpoint($tablet-lg) {
    padding-left: 40px;
    padding-right: 40px;
  }

  > div {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.3s ease-in-out 0.2s,
                opacity 0.3s ease-in-out 0.2s;
  }

  .lang-jp & h5 {
    font-size: 1.1em;
    line-height: 1.6em;
  }
}
