// extends must use % syntax to avoid writing classes to multiple component css files

// defines max grid constraint / centered
%container {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
}

// defines page marges roughly equal to one column
%page-margins {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  // media queries
  @include breakpoint($tablet-md) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include breakpoint($mobile-lg) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include breakpoint($mobile-sm) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

%full-bleed {
  @extend %page-margins;
  width: calc(100% + 200px);
  margin-left: -100px;
  // media queries
  @include breakpoint($tablet-md) {
    width: calc(100% + 120px);
    margin-left: -60px;
  }
  @include breakpoint($mobile-lg) {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

// clearfix, for clearing parents with floating children
%clearfix {
  clear: both;

  &:after { clear: both; }

  &:before,
  &:after {
    content: '';
    display: table;
  }
}
