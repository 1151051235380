@import 'styles/_sass-includes.scss';

.whatwedo {
  z-index: 4;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 150px;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 100%;
    height: auto;
    position: relative;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 100px;
  }

  .sectiontitle {
    margin-bottom: 140px;
    // media queries
    @include breakpoint($tablet-lg) {
      margin-bottom: 80px;
    }
    @include breakpoint($mobile-lg) {
      margin-bottom: 20px;
    }
  }
}

.whatwedo__block {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
  // media queries
  @include breakpoint($tablet-lg) {
    opacity: 1;
  }

  &.is-active {
    opacity: 1;
  }
}

.whatwedo__window {
  position: fixed;
  left: 50%;
  top: 0;
  height: 100vh;
  width: 50%;
  background-color: $offwhite;
  pointer-events: none;
}

.whatwedo__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 110px;
  height: 110px;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.whatwedo__lottie {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  // media queries
  @include breakpoint($tablet-lg) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    opacity: 1;
    height: 300px;
    width: auto;
    z-index: 1;
  }

  &.lottie-2 > div:not(.lottie__circle) {
    // media queries
    @include breakpoint($tablet-lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% + 350px) !important;
    }
    @include breakpoint($mobile-lg) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto !important;
      width: calc(100% + 250px) !important;
    }
  }

  &.is-active {
    opacity: 1;
    z-index: 11;
  }
}

.lottie-1,
.lottie-0 {
  width: 300px;
  height: 308px;
}

.lottie-2 {
  width: 750px;
  height: 758px;
  max-width: 100%;
  // media queries
  @include breakpoint($tablet-lg) {
    width: auto;
  }
}

.lottie__circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: $primary;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@import 'styles/_sass-includes.scss';

.whatwedo {
  z-index: 4;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 150px;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 100%;
    height: auto;
    position: relative;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 100px;
  }

  .sectiontitle {
    margin-bottom: 140px;
    // media queries
    @include breakpoint($tablet-lg) {
      margin-bottom: 80px;
    }
    @include breakpoint($mobile-lg) {
      margin-bottom: 20px;
    }
  }
}

.whatwedo__block {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
  // media queries
  @include breakpoint($tablet-lg) {
    opacity: 1;
  }

  &.is-active {
    opacity: 1;
  }
}

.whatwedo__window {
  position: fixed;
  left: 50%;
  top: 0;
  height: 100vh;
  width: 50%;
  background-color: $offwhite;
  pointer-events: none;
}

.whatwedo__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 110px;
  height: 110px;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.whatwedo__lottie {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  // media queries
  @include breakpoint($tablet-lg) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    opacity: 1;
    height: 300px;
  }

  &.lottie-2 > div:not(.lottie__circle) {
    // media queries
    @include breakpoint($tablet-lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% + 350px) !important;
    }
    @include breakpoint($mobile-lg) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto !important;
      width: calc(100% + 250px) !important;
    }
  }

  &.is-active {
    opacity: 1;
    z-index: 11;
  }
}

.lottie-2 {
  width: 750px;
  max-width: 100%;
  // media queries
  @include breakpoint($tablet-lg) {
    width: auto;
  }
}

.lottie__circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: $primary;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.whatwedo__text {
  width: calc(-13.3px + 66.66%);
  margin-right: 40px;
  float: left;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
    float: none;
    margin-right: 0;
  }
}

.whatwedo__textwrapper {
  z-index: 10;
}

.whatwedo__aside {
  width: calc(-26.7px + 33.33%);
  float: left;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }
}
