@import 'styles/_sass-includes.scss';

.careers {
  padding-top: 120px;
  padding-bottom: 120px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

.jobpost {
  background-color: $offwhite;
  padding: 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  // media queries
  @include breakpoint($mobile-lg) {
    padding: 30px;
  }
}

.jobpost__bgmap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: contain;
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.6;
  height: 550px;
}

.jobpost__content {
  z-index: 2;
  position: relative;
}

.careers__header {
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 0;
  }

  img {
    width: calc(100% + 150px);
    max-width: none;
    position: absolute;
    right: 0;
    top: 120px;
    // media queries
    @include breakpoint($tablet-lg) {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block;
    }
  }
}

.link__privacypolicy {
  font-size: 0.8em;
}

.oneSecDelay {
  opacity: 0;
  animation: fadeIn .5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
