@import 'styles/_sass-includes.scss';

.locationsPage__image {
    width: 100%;
}

.locationsPage__carousel {
  aspect-ratio: 1/1;
  width: 80%;
  margin: 40px auto 40px auto;
  @include breakpoint($mobile-sm) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.locationsPage__circle__US {
  width: 40px;
  height: 40px;
  background-color: $primary;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  top: 47%;
  @include breakpoint($tablet-lg) {
    width: 32px;
    height: 32px;
  }
  @include breakpoint($mobile-lg) {
    width: 24px;
    height: 24px;
  }
}

.locationsPage__circle__JP {
  width: 40px;
  height: 40px;
  background-color: $primary;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  top: 48%;
  @include breakpoint($tablet-lg) {
    width: 32px;
    height: 32px;
  }
  @include breakpoint($mobile-lg) {
    width: 24px;
    height: 24px;
  }
}

.locationsPage {

  .btn__container {
    @include breakpoint($mobile-lg) {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      flex-direction: column;
      margin-top: 40px;
      li {
        text-align: center;
        margin: 0px auto 20px auto;
      }
    }
  }

  .btn {
    @include breakpoint($mobile-lg) {
      @include font(bold);
      @include font-size(b1);
      background-color: $primary;
      color: $white;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      z-index: 1000;
      width: 240px;
      justify-content: center;
      padding: 20px 20px 20px 20px;
      cursor: pointer;
      position: relative;
      left: 0;
      margin: 0 auto;
      transition: left 0.2s ease-in-out, color 0.2s ease-in-out;
    
      &:before {
        content: none;
        // width: 35px;
        // height: 2px;
        // background-color: $primary;
        // margin-right: 17px;
        // display: block;
        // top: 2px;
        // position: relative;
        // transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }
    
      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
        // color: $dark;
        // left: -15px;
    
        &:before {
          // background-color: $dark;
          // width: 50px;
        }
      }
    }
  }

}