@import 'styles/_sass-includes.scss';

.privacypolicy {
  padding-top: 120px;
  padding-bottom: 120px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

.policypost {
  padding: 0 64px 64px 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  // media queries
  @include breakpoint($mobile-lg) {
    padding: 0px;
  }

  .markdown {
    font-size: 0.8em;

    ul {
      list-style: disc;
      padding-left: 3em;
      margin-bottom: 0.8em;
      @include breakpoint($mobile-lg) {
        padding-left: 1em;
      }
    }

    table {
      margin: 2em 0;

      tr {
        border-top: 1px solid #000;

        &:last-child {
          border-bottom: 1px solid #000;
        }

        td {
          &:first-child {
            width: 30%;
          }
        }
      }

      th, td {
        padding: 0.5em 1em;
      }

    }
  }
}

.privacypolicy__header {
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 0;
  }

  img {
    width: calc(100% + 150px);
    max-width: none;
    position: absolute;
    right: 0;
    top: 120px;
    // media queries
    @include breakpoint($tablet-lg) {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block;
    }
  }

  h1.typ--jp {
    font-size: 40px;
    line-height: 1.4;
    font-weight: 400;
  }
}
