@import 'styles/_sass-includes.scss';

.teamgrid {
  @extend %clearfix;
  display: block;
  position: relative;
  width: calc(100% + 54px);
  margin-left: -27px;
  // media queires
  @include breakpoint($mobile-lg) {
    width: 100%;
    margin-left: 0;
  }

  .lang-jp & .sectiontitle {
    font-weight: 600;
  }

  > div {
    width: calc(33.3333333% - 54px);
    float: left;
    margin: 0 27px 54px;
    cursor: pointer;
    // media queries
    @include breakpoint($desktop-sm) {
      width: calc(50% - 54px);
    }
    @include breakpoint($mobile-lg) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    @include breakpoint($tablet-lg, min-width) {
      &:not(.is-expanded):hover {
        .teamgrid__window {
          clip-path: circle(52.5% at center) !important;
        }

        .teamgrid__memberinfo {
          transform: translateX(-30px);
        }
      }
    }
  }
}

.teamgrid__window {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: circle(50% at center);
  transition: clip-path 0.4s ease-out;

  img {
    height: 130%;
    object-fit: contain;
    max-width: none;
  }
}

_:-ms-lang(x), .teamgrid__window {
  overflow: hidden;
}

.teamgrid__member {
  width: 100%;
  padding-bottom: 28.62%;
  position: relative;
  // media queries
  @include breakpoint($desktop-sm) {
    padding-bottom: 44.62%;
  }
  @include breakpoint($mobile-lg) {
    padding-bottom: 100%;
  }
}

.teamgrid__memberinfo {
  position: absolute;
  top: 20px;
  left: 0;
  transition: transform 0.4s ease-out 0.04s;
  // media queries
  @include breakpoint($mobile-sm) {
    top: 10px;
  }
}
