.form__field {
  position: relative;
  padding-bottom: 30px;
}

.form__input {
  @include font-size(b2);
  @include font(regular);
  appearance: none;
  padding: 0.8em;
  border: 2px solid transparent;
  width: 100%;
  background: $white;
  box-shadow: 0 0 70px 16px rgba($black, 0.06);
  border-radius: 3px;

  &.has-error {
    border-color: $primary;
  }

  &[type='file'] {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    background: transparent;
    border: 0;
  }
}

textarea.form__input {
  min-height: 180px;
  resize: none;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.form__label,
.form__label .label {
  @include font(semibold);
  @include font-size(b2);
  margin-bottom: 10px;
  display: block;
}

.form__error {
  @include font(semibold);
  @include font-size(b2);
  text-transform: uppercase;
  color: $primary;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form__sectiontitle {
  @include font(semibold);
  margin-bottom: 10px;
}

.form__fieldrow {
  margin-bottom: 45px;
}

.form__fields {
  position: relative;
}

.form__file {
  position: relative;

  input[type='file'] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
