@import 'styles/_sass-includes.scss';

.dropdown {
  position: relative;

  &.is-open {
    z-index: 1000;
  
    .dropdown__toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background: $white;
    }
  
    .dropdown__menu {
      transition: max-height 0.15s ease-out, opacity 0.15s ease-out, transform 0.15s ease-out;
      max-height: 320px;
      opacity: 1;
      transform: none;
    }
  
    .dropdown__icon { color: $primary; }
  }
}

.dropdown__toggle {
  border-bottom: 1px solid $primary;
  color: $primary;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown--input .dropdown__menu {
  .dropdown__item {
    display: flex;
    align-items: center;
  }

  img {
    width: 30px;
    margin-right: 20px;
  }
}

.dropdown__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown__phantom-menu {
  display: none;
}

.dropdown__menu {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: absolute;
  // bottom: -80px;
  background: $white;
  left: 0;
  width: 100%;
  z-index: 1;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out, transform 0.2s ease-out;
  border: 2px solid $black;
  max-height: 0;
  opacity: 0;
  transform: translateY(-47px);
  z-index: 20;
  // media queries
  @include breakpoint($desktop-sm) {
    // bottom: -4rem;
  }
  @include breakpoint($tablet-md) {
    bottom: auto;
    top: 0;
  }

  li {
    position: relative;
    cursor: pointer;
    padding: 5px 32px;
    width: 100%;
    // media queries
    @include breakpoint($mobile-lg) {
      @include font-size(body);
    }

    &:hover,
    &.is-selected {
      background-color: $primary;
    }

    &.is-selected:before {
      @extend %icon;
      @extend %icon-checkmark;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.dropdown__item--active:before {
  position: absolute;
  right: 1.3em;
  top: 50%;
  transform: translateY(-50%);
}
