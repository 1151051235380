@font-face {
  font-family: 'wil';
  src:  url('../assets/fonts/wil.eot?2jvyft');
  src:  url('../assets/fonts/wil.eot?2jvyft#iefix') format('embedded-opentype'),
    url('../assets/fonts/wil.ttf?2jvyft') format('truetype'),
    url('../assets/fonts/wil.woff?2jvyft') format('woff'),
    url('../assets/fonts/wil.svg?2jvyft#wil') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icon;
}

.icon-checkmark:before {
  content: "\e915";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-fact-alter-ego:before {
  content: "\e907";
}
.icon-fact-favorite-quote:before {
  content: "\e908";
}
.icon-fact-focus-area:before {
  content: "\e909";
}
.icon-fact-giving-back:before {
  content: "\e90a";
}
.icon-fact-global-citizenship:before {
  content: "\e90b";
}
.icon-fact-hidden-talent:before {
  content: "\e90c";
}
.icon-fact-on-the-weekend:before {
  content: "\e90d";
}
.icon-linkedin:before {
  content: "\e90e";
}
.icon-long-arrow-down:before {
  content: "\e90f";
}
.icon-long-arrow-left:before {
  content: "\e910";
}
.icon-long-arrow-right:before {
  content: "\e911";
}
.icon-long-arrow-up:before {
  content: "\e912";
}
.icon-menu:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}
