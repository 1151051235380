@import '~styles/_sass-includes.scss';

.home__new {
  position: relative;
  // height: 100vh;
  height: auto;
  width: 100vw;
  //position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // media queries
  @include breakpoint($tablet-lg) {
    flex-direction: column;
    height: auto;
    position: relative;
  }
}

.circle__content__new {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  max-width: 300px;
  left: 50%;
  transform: translate(-50%, 0%);
  // media queries
  @include breakpoint($tablet-sm) {
    max-width: 70%;
  }
  @include breakpoint($mobile-lg) {
    max-width: 50%;
  }
  @include breakpoint($mobile-sm) {
    max-width: 60%;
  }

  h1 {
    font-size: 48px;
    line-height: 0.9;
    text-align: center;
    // media  queries
    @include breakpoint($tablet-lg) {
      font-size: 48px;
    }
    @include breakpoint($tablet-sm) {
      font-size: 38px;
    }
    @include breakpoint($mobile-lg) {
      font-size: 32px;
    }

    .lang-jp & {
      font-size: 48px;
      line-height: 1;
      // media  queries
      @include breakpoint($tablet-lg) {
        font-size: 48px;
      }
      @include breakpoint($tablet-sm) {
        font-size: 38px;
      }
      @include breakpoint($mobile-lg) {
        font-size: 32px;
      }
    }
  }

  h4 {
    line-height: 1.2;
    text-align: center;
    // media  queries
    @include breakpoint($tablet-sm) {
      font-size: 1.1em;
    }
    @include breakpoint($mobile-lg) {
      font-size: 1em;
    }
    .lang-jp & {
      font-size: 1.2em;

      @include breakpoint($tablet-sm) {
        font-size: 1em;
      }
      @include breakpoint($mobile-lg) {
        font-size: 1em;
      }
    }
  }

  button {
    pointer-events: all;
  }

}

.herocircle__new {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.is-animating) {
    .circle {
      transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
      // media queries
      @include breakpoint($tablet-md, min-width) {
        &:hover {
          width: 760px !important;
          height: 760px !important;
          // media queries
          @include breakpoint($desktop-md) {
            width: 605px !important;
            height: 605px !important;
          }
          @include breakpoint($tablet-lg) {
            width: 600px !important;
            height: 600px !important;
          }
        }
      }
    }
  }

  [class^="icon-"] {
    @include font-size(h2);
    transform: translateY(60px);
    // media queries
    @include breakpoint($tablet-lg) {
      transform: translateY(50px);
    }
    @include breakpoint($tablet-sm) {
      transform: translateY(44px);
    }
    @include breakpoint($mobile-lg) {
      @include font-size(h4);
      transform: translateY(28px);
    }
    @include breakpoint($mobile-sm) {
      @include font-size(h4);
      transform: translateY(12px);
    }
  }
}

.circle__new {
  width: 460px;
  height: 460px;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $primary;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 1;
  cursor: pointer;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 440px;
    height: 440px;
  }
  @include breakpoint($tablet-sm) {
    width: 380px;
    height: 380px;
  }
  @include breakpoint($mobile-lg) {
    width: 323px;
    height: 323px;
  }
  @include breakpoint($mobile-sm) {
    width: 277px;
    height: 277px;
  }
}

.home__wrapper__new {
  // media queries
  @include breakpoint($tablet-lg) {
  }
  @include breakpoint ($mobile-lg) {
  }
  @include breakpoint ($mobile-md) {
    margin-top: 180px;
  }
}

.home__splash {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .home__splash__logo {
    opacity: 0;
    width: 300px;
    // media queries
    @include breakpoint($mobile-md) {
      width: 200px;
    }
  }
}

.home__new__approach__circle__container {
  position: relative;
}

.home__new__approach__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 80px;
  height: 80px;
  z-index: 10;
  position: absolute;
  top: 0;
  transform: translate(220px, 280px);
  // media queries
  @include breakpoint($desktop-sm) {
    transform: translate(220px, 308px);
  }
  @include breakpoint($tablet-lg) {
    transform: translate(200px, 240px);
  }
  @include breakpoint($tablet-sm) {
    transform: translate(180px, 220px);
  }
  @include breakpoint($mobile-lg) {
    top: 65%;
    left: 65%;
    transform: translate( 0px, 0px);
  }
  @include breakpoint($mobile-sm) {
    width: 50px;
    height: 50px;
    top: 65%;
    left: 65%;
    transform: translate( 0px, 0px);
  }
}

.home__new__reverseForMobile {
  @include breakpoint($mobile-lg) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.home__new__bridge{
  @include breakpoint($mobile-lg) {
    width: 100%;
  }
}

.home__new__carousel__left{
  width: 480px;
  height: 480px;
  left: -160px;
  top: -120px;
  @include breakpoint($desktop-sm) {
    left: -120px;
  }
  @include breakpoint($tablet-lg) {
    width: 420px;
    height: 420px;
  }
  @include breakpoint($tablet-sm) {
    width: 360px;
    height: 360px;
    top: -80px;
    left: -100px;
  }
  @include breakpoint($mobile-lg) {
    width: 360px;
    height: 360px;
    top: 0px;
    left: 0px;
    margin: 40px auto 60px auto;
  }
  @include breakpoint($mobile-sm) {
    width: 240px;
    height: 240px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

.home__new__carousel__right{
  width: 480px;
  height: 480px;
  top: -40px;
  right: 0px;
  @include breakpoint($tablet-lg) {
    width: 420px;
    height: 420px;
    top: 0px;
  }
  @include breakpoint($tablet-sm) {
    width: 360px;
    height: 360px;
  }
  @include breakpoint($mobile-lg) {
    width: 360px;
    height: 360px;
    top: 0px;
    left: 0px;
    margin: 40px auto 60px auto;
  }
  @include breakpoint($mobile-sm) {
    width: 240px;
    height: 240px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

.home__new .flickity-page-dots {
  bottom: 90px;
  text-align: center;
  @include breakpoint($tablet-lg) {
    bottom: 100px;
  }
  @include breakpoint($tablet-sm) {
  }
  @include breakpoint($mobile-lg) {
  }
  @include breakpoint($mobile-sm) {
    bottom: 90px;
  }
}

.home__new__ourblog__wrapper {
  display: flex;
  align-items: center;
  @include breakpoint($mobile-lg) {
    flex-direction: column;
  }
}

.home__new__ourblog__container{
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include breakpoint($mobile-lg) {
    text-align: center;
    justify-content: center;
    flex-direction: column;
    &.col--10{
      margin: 0px;
    }
  }
}

.home__new__ourblog__mobileBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.home__new__ourblog{
  p {
    display: block;
    // position: absolute;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    border: 1px solid;
    text-align: center;
    line-height: 360px;
    font-size: 1.6em;
    // float: left;
    padding-left: 20px;
    @include breakpoint($desktop-md) {
      width: 300px;
      height: 300px;
      line-height: 300px;
    }
    @include breakpoint($desktop-sm) {
      width: 280px;
      height: 280px;
      line-height: 280px;
    }
    @include breakpoint($tablet-lg) {
      width: 260px;
      height: 260px;
      line-height: 260px;
    }
    @include breakpoint($tablet-sm) {
      width: 220px;
      height: 220px;
      line-height: 220px;
    }
    @include breakpoint($mobile-lg) {
      width: 220px;
      height: 220px;
      line-height: 40px;
      text-align: center;
      padding-left: 0px;
      padding-top: 64px;
    }
  }
}

.home__new__ourblog__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute;
  top: 0;
  transform: translate(32px, 32px);
  // media queries
  @include breakpoint($desktop-md) {
    transform: translate(26px, 26px);
  }
  @include breakpoint($desktop-sm) {
    transform: translate(20px, 20px);
  }
  @include breakpoint($tablet-lg) {
    transform: translate(20px, 20px);
  }
  @include breakpoint($tablet-sm) {
    transform: translate(14px, 14px);
  }
  @include breakpoint($mobile-lg) {
    transform: translate(-80px, 12px);
  }
}

.home__new__ourblog__layout {
  position: relative;
  display: flex;
  align-items: center;

  @include breakpoint($mobile-lg) {
    flex-direction: column;
  }
}

.home__new__ourblog__image{
  // width: 280px;
  // height: 280px;
  aspect-ratio: 1/1;
  margin-left: -40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include breakpoint($desktop-md) {
    margin-left: -80px;
  }

  @include breakpoint($tablet-md) {
    margin-left: -60px;
  }
  
  @include breakpoint($mobile-lg) {
    width: 140px;
    height: 140px;
    margin: -60px 0px 20px 0px;
  }
}

.home__new__ctabanner__wrapper{
  // background-image: -webkit-gradient(linear, left top, right top, from(#2af598), to(#009efd));
  background-image: -webkit-linear-gradient(left, $white 0%, $primary-light 50%, $white 100%);
  background-image: linear-gradient(0deg, $white 0%, $primary-light 50%, $white 100%);
  @include breakpoint($tablet-lg) {
    margin-top: 60px;
  }
  @include breakpoint($tablet-sm) {
    margin-top: 20px;
  }
  @include breakpoint($mobile-lg) {
    margin-top: 80px;
    text-align: center;
  }

  &.full-bleed {
    width: calc(100% + 600px);
    margin-left: -300px;
    padding-left: 300px;
    padding-right: 300px;
  }
}



.home__new__ctabanner__container{
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($mobile-lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h5 {
    line-height: 1.2;
    @include breakpoint($tablet-lg) {
      font-size: 1.1em;
    }
    @include breakpoint($tablet-sm) {
      font-size: 1em;
    }
    @include breakpoint($mobile-lg) {
      font-size: 0.9em;
    }
  }
}

.home__new__ctabanner__button a {
  background: $primary;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 180px;
  max-width: 280px;
  padding: 10px 25px;
  color: $white;
  transition: 0.3s ease-in-out;
  font-weight: 500;

  @include breakpoint($mobile-lg) {
    @include font(bold);
    @include font-size(b1);
    width: 240px;
    margin-top: 20px;
  }

  &:hover {
  background: $white;
  color: $primary;
  border: 1px solid $primary;
  }
  
}

.layout--back{
  z-index: -1;
}

.home__new {

  .btn__container {
    @include breakpoint($mobile-lg) {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      flex-direction: column;
      margin-top: 40px;
      li {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    @include breakpoint($mobile-lg) {
      @include font(bold);
      @include font-size(b1);
      background-color: $primary;
      color: $white;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      z-index: 1000;
      width: 240px;
      justify-content: center;
      padding: 20px 20px 20px 20px;
      cursor: pointer;
      position: relative;
      left: 0;
      margin: 0 auto;
      transition: left 0.2s ease-in-out, color 0.2s ease-in-out;
    
      &:before {
        content: none;
        // width: 35px;
        // height: 2px;
        // background-color: $primary;
        // margin-right: 17px;
        // display: block;
        // top: 2px;
        // position: relative;
        // transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }
    
      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
        // color: $dark;
        // left: -15px;
    
        &:before {
          // background-color: $dark;
          // width: 50px;
        }
      }
    }
  }

}

.home__new__borderTop__mobile {
  @include breakpoint($mobile-lg) {
    border-top: 2px solid $dark;
    padding-top: 28px;
  }
}

.home__new__ourPrinciples__image {
  text-align: center;
  img {
    @include breakpoint($mobile-lg) {
      width: 80%;
      margin-top: 28px;
    }
    @include breakpoint($mobile-sm) {
      width: 90%;
      margin-top: 28px;
    }
  }
}

.home__new__stats {
  background-color: $primary-extralight;
}

.home__new__stats__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.home__new__stats__box {
  @include breakpoint($mobile-lg) {
    width: 50%;
  }
}

.home__new__stat__number {
  font-size: 80px;
  font-weight: 300;
  color: $primary;
  @include breakpoint($mobile-lg) {
    font-size: 64px;
  }
  @include breakpoint($mobile-sm) {
    font-size: 56px;
  }
}

.home__new__stat__caption {
  margin-top: -12px;
  line-height: 1em;
  @include breakpoint($mobile-sm) {
    font-size: 0.8em;
  }
}

.stat__text__small {
  display: inline;
  font-size: 50%;
  font-weight: 400;
}