@import 'styles/_sass-includes.scss';

.clientgrid {
  margin-bottom: 100px;
  // media queries
  @include breakpoint($tablet-lg) {
    margin-bottom: 90px;
  }
  @include breakpoint($mobile-md) {
    margin-bottom: 50px;
  }
}

.clientgrid__layout {
  display: flex;
  flex-wrap: wrap;
  // media queries
  @include breakpoint($tablet-sm) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  > div {
    margin: 0 30px;
    width: calc(25% - 60px);
    height: 140px;
    position: relative;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    // media queries
    @include breakpoint($desktop-sm) {
      margin: 0 20px;
      width: calc(25% - 40px);
    }
    @include breakpoint($tablet-lg) {
      width: calc(33.3333333% - 60px);
      margin-left: 30px;
      margin-right: 30px;
    }
    @include breakpoint($mobile-md) {
      width: calc(50% - 60px);
      margin-left: 30px;
      margin-right: 30px;
      height: 120px;
    }
  }

  img {
    width: 100%;
  }
}

*::-ms-backdrop,
.clientgrid__layout > div {
  display: inline-block;
} /* IE11 */

.clientgrid__description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  max-height: none;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  padding-bottom: calc(100% + 40px);
  width: calc(100% + 40px);

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clientgrid__descriptiontext {
    transform: matrix(1, 0, 0, 1, 0, 30);
    line-height: 1.4;
    padding: 10px 20px 10px;
    text-align: center;
  }
}

.clientgrid__exitinfo {
  @include font-size(b3);
  line-height: 1.5;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  display: block;
  color: rgba($black, 0.5);
}
