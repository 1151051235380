.disable-scroll {
  overflow: hidden;
}

// align content left for box model layouts
.layout--left {
  float: left !important;
}

// align content left for box model layouts
.layout--right {
  float: right !important;
}

// force position relative, used to constrain absolute children
.layout--relative {
  position: relative;
}

.layout--block {
  display: block;
}

// clearfix class, % defined in extends
.cf {
  @extend %clearfix;
}

.pagecontent {
  min-height: calc(100vh - 110px);
  position: relative;
  // media queries
  @include breakpoint($mobile-lg) {
    min-height: calc(100vh - 80px);
  }
}

.scroller {
  position: fixed;
  height: 100vh;
  padding-top: 110px;
  width: 100vw;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
  z-index: 4;
  -webkit-overflow-scrolling: touch;
  // media queries
  @include breakpoint($mobile-lg) {
    padding-top: 60px;
  }
}
