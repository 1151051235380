@import 'styles/_sass-includes.scss';

.header__wrapper {
  position: relative;
  width: 100%;
  height: 110px;
  display: block;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 70px;
  }
}

.header {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  position: fixed;
  width: 100%;
  z-index: 5;
  transition: transform 1s ease-in-out;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 80px;
  }
  @include breakpoint($mobile-sm) {
    padding-left: 25px;
    padding-right: 25px;
  }

  img {
    margin-top: -30px;
    width: 70px;
    // media queries
    @include breakpoint($mobile-lg) {
      width: 50px;
    }
  }
}

.header__links {
  @include font(medium);
  display: flex;

  opacity: 1;
  transition: 1s;

  a {
    color: $dark;
    position: relative;
    margin-top: 4px;

    &:hover {
      color: $primary;
    }
  }

  li {
    @include font-size(h6);
    margin-left: 20px;
    &:last-of-type {
      margin-right: 40px;
    }
  }

  .is-active:after {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $primary;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }


  .is-shown {
    opacity: 1;
    pointer-events: auto;
    transition: 0.5s;
  }

  .is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }

}


