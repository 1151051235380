@import '~styles/_sass-includes.scss';

.markdown {
  @include font-size(h6);

  .markdown__element-container {
    position: relative;
    display: block;
  }

  em {
    font-style: italic;
  }

  b {
    @include font(bold);
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  ol li {
    display: block;
  }

  pre {
    background-color: rgba($grey, 0.5);
    padding: 5px 10px;
  }

  table {
    width: 100%;
    border: 1px solid $grey;
    border-radius: 5px;
  }

  thead tr {
    border-bottom: 1px solid $grey;
    background-color: rgba($grey, 0.1);
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.04);
    
    th {
      padding: 5px 15px;
    }
  }

  td {
    padding: 5px 15px;
  }

  tbody {
    tr:nth-of-type(1n) {
      background-color: rgba($grey, 0.3);
    }

    tr:nth-of-type(2n) {
      background-color: $offwhite;
    }

    tr:first-of-type td {
      padding-top: 10px;
    }
  }
}
