@import 'styles/_sass-includes.scss';

.blog {
  max-width: $content-width;
  margin: 0 auto;
  // height: calc(100vh - 110px);
  // overflow: hidden;
  display: flex;
  z-index: 1;
  // media queries
  @include breakpoint($tablet-md) {
    flex-direction: column;
    overflow: visible;
    height: auto;
  }

  // &:after {
  //   content: '';
  //   position: fixed;
  //   right: 0;
  //   top: 0;
  //   height: 100vh;
  //   // background-color: $offwhite;
  //   width: 50vw;
  //   z-index: -1;
  //   // media queries
  //   @include breakpoint($tablet-md) {
  //     display: none;
  //   }

  //   > div {
  //     z-index: 10;
  //     position: relative;
  //   }
  // }

  .lang-jp & h1.typ--jp {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600;
  }

  .lang-jp & h1.typ--jp.typ--h3 {
    font-size: 28px;
    line-height: 1.5;
  }

}

.insights__circle {
  width: 135px;
  height: 135px;
  background-color: $primary;
  border-radius: 50%;
  flex-shrink: 0;
  // media queries
  @include breakpoint($desktop-sm) {
    width: 8rem;
    height: 8rem;
  }
}

.insights__filters {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 120px;
  flex: 1;
  z-index: 2;
  // media queries
  @include breakpoint($desktop-sm) {
    padding-bottom: 6rem;
  }
  @include breakpoint($tablet-md) {
    flex: auto;
  }
  @include breakpoint($mobile-sm) {
    padding-bottom: 60px;
  }

  .tabs {
    flex-wrap: wrap;

    li {
      white-space: nowrap;
      margin-bottom: 20px;
    }
  }
}

.insight__title__wrapper {
  width: 100%;
  z-index: 10;
}

.insights__window {
  height: 100%;
  width: 100%;
  overflow: auto;
  flex: 1;
  z-index: 1;
  // media queries
  @include breakpoint($tablet-md) {
    flex: auto;
    overflow: visible;
  }
}

.blog__item {
  border-top: 2px solid $dark;
  padding-top: 25px;
  padding-bottom: 55px;
  width: calc(50% - 28px);

  @include breakpoint($mobile-lg) {
    width: 100%;
  }

  a {
    color: $dark;
    cursor: pointer;
  }

}

.blog__item__img{
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  float: left;

  img {
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
  }

  // media queries
  @include breakpoint($desktop-sm) {
    width: 100px;
    height: 100px;
  }
  @include breakpoint($tablet-md) {
    float: none;
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
    // margin: auto;
  }
  @include breakpoint($mobile-sm) {
  }

}

.blog__item__date{
  margin-bottom: 0;
  font-size: 0.8em;
}

.blog__author__link {
  &:hover{
    color: $primary;
  }
}

.blog__layout{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    display: block;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  ul{
    display: flex;
  }

  .page-item{
    margin-left: 10px;
    margin-right: 10px;

    &.active{
      font-weight: 600;
    }
  }

}

.blog__followUsOn__wrapper {
  position: relative;
  // z-index: -1;
}

.blog__social {
  ul{
    display: flex;
  }
  li {
    font-size: 28px;
    margin-right: 20px;
  }
}

.blog__disclaimer {
  width: 80%;
  margin: 0 auto;
  line-height: 0.8em;

  @include breakpoint($tablet-lg) {
    width: 90%;
  }

  a {
  font-size: 0.8em;
  color: $lightDark;
  &:hover {
    color: $dark;
    }
  }
}

.icon-x-tentative:before {
  content: "";
  background-color: #ff626f;
  display: inline-block;
  height: 1.6rem;
  width: 1.6rem;
  -webkit-mask: url("../../../src/assets/icons/x.svg");
  mask: url("../../../src/assets/icons/x.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle;
}