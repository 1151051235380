@import 'styles/_sass-includes.scss';

.jobpost__body {
  h1, h2, h3, h4, h5 {
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  ul {
    list-style-type: disc;
    margin-bottom: 30px;
    margin-left: 20px;
  }

  b {
    font-weight: 700;
  }
}

.lang-jp .jobpost__body__other {
  display: none;
}