@import 'styles/_sass-includes.scss';

.app {
  width: 100vw;
  overflow: hidden;
}

.lang-jp .typ--jp {
  font-family: sofia-pro,'Noto Sans JP', sans-serif !important;
}
