@import 'styles/_sass-includes.scss';

.footer {
  @extend %theme--dark;
  padding-top: 110px;
  padding-bottom: 40px;
  background-image: url(../../assets/img/footer_bg.svg);
  background-repeat: no-repeat;
  background-size: 5000px;
  background-position: top center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $dark;
    // media queries
    @include breakpoint($tablet-lg) {
      top: 110px;
      z-index: -1;
    }
  }

  ul {
    @include font(medium);
    // media queries
    @include breakpoint($mobile-xsm) {
      @include font-size(b2);
    }
  }
}

*::-ms-backdrop, .footer { background-color: $dark; } /* IE11 */

.footernext {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $white;
  // media queries
  @include breakpoint($tablet-lg) {
    justify-content: center;
    margin-bottom: 40px;
  }

  &:hover {
    color: $white;
  }
}

.footernext__cta {
  position: relative;
  z-index: 20;
  top: -20px;
  left: 60px;
  color: $white;
  // media queries
  @include breakpoint($tablet-lg) {
    margin-left: -30px;
    left: 40px;
  }

  h1 {
    line-height: 1;
  }
}

.footernext__circle {
  width: 160px;
  height: 160px;
  background-color: $primary;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 90px;
    height: 90px;
  }

  [class^="icon-"] {
    font-size: 70px;
    color: $dark;
    // media queries
    @include breakpoint($tablet-lg) {
      font-size: 30px;
    }
  }
}

.footer__col6 {
  width: 50%;
  float: left;
}

.footer__address {
  margin-top: 15px;

  a {
    @include font-size(b3);
    display: block;
    line-height: 1.8;
  }
}
