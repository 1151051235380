@import 'styles/_sass-includes.scss';

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.carousel {
  &.show-arrows {
    padding: 0 100px;
  }

  &.show-dots {
    padding-bottom: 40px;

    .carousel__arrows {
      top: calc(50% - 20px);
    }
  }
}

.carousel__arrows {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: $primary;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  
  [class^="icon-"] {
    font-size: 30px;
  }
}

.carousel:after {
  content: '';
  margin-bottom: 10px;
  display: block;
}

.vehicle--carousel .carousel__slide {
  @extend %page-margins;
  height: 30vh;
  display: flex;
  align-items: center;
}

.carousel__slide {
  width: 100%;
  img {
    width: 100%;
  }
}

.carousel__caption {
  text-align: center;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1;
  // media queries
  @include breakpoint($tablet-lg) {
    text-align: center;
  }
}

.has-caption .flickity-page-dots {
  bottom: -70px;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid $dark;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: $primary;
  border-color: $primary;
}
