@import 'styles/_sass-includes.scss';

.tabs {
  display: flex;
  flex-wrap: wrap;
  // media queries
  @include breakpoint($mobile-sm) {
    width: calc(100% + 50px);
    margin-left: -25px;
    overflow-x: auto;
    padding: 0 25px 25px;
  }

  li {
    @include font(medium);
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    padding: 0 2px;

    &.is-active {
      border-color: $primary;
    }
  }
}
