// font mixin for selecting the font weight & family
@mixin font($family) {
  @if $family == 'light' {
    font-family: sofia-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
  } @else if $family == 'regular' {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
  } @else if $family == 'medium' {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
  } @else if $family == 'bold' {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
}

// font size mixin for defining scale
@mixin font-size($type) {
  @if $type == 'h1' {
    font-size: 50px;
    line-height: 1.08;
  }

  @if $type == 'h2' {
    font-size: 38px;
    line-height: 1.15789474;
  }

  @if $type == 'h3' {
    font-size: 30px;
    line-height: 1.27777778;
  }

  @if $type == 'h4' {
    font-size: 22px;
    line-height: 1.36363636;
  }

  @if $type == 'h5' {
    font-size: 20px;
    line-height: 1.9;
  }

  @if $type == 'h6' {
    font-size: 18px;
    line-height: 1.9;
  }

  @if $type == 'b1' {
    font-size: 16px;
    line-height: 1.875;
  }

  @if $type == 'b2' {
    font-size: 14px;
    line-height: 1.57142857;
  }

  @if $type == 'b3' {
    font-size: 10px;
    line-height: 2.4;
  }

  // For Japanese text
  @if $type == 'h1-jp' {
    font-size: 50px;
    line-height: 1.08;
  }

  @if $type == 'h2-jp' {
    font-size: 30px;
    line-height: 1.3;
  }

  @if $type == 'h3-jp' {
    font-size: 30px;
    line-height: 1.27777778;
  }

  @if $type == 'h4-jp' {
    font-size: 22px;
    line-height: 1.36363636;
  }

  @if $type == 'h5-jp' {
    font-size: 16px;
    line-height: 1.6;
  }

  @if $type == 'b1-jp' {
    font-size: 14px;
    line-height: 1.6;
  }

  @if $type == 'b2-jp' {
    font-size: 14px;
    line-height: 1.57142857;
  }

  @if $type == 'b3-jp' {
    font-size: 10px;
    line-height: 2.4;
  }
}
