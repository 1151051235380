@import 'styles/_sass-includes.scss';

.quote {
  position: fixed;
  display: flex;
  top: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  z-index: 100;
  background-color: $white;

  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.quote__tag {
  @include font(bold);
  @include font-size(b2);
  display: block;
  color: $primary;
  text-align: right;
  position: absolute;
  top: -70px;
  right: 25px;
}

.quote__bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    max-width: none;
    width: 150vw;
    height: 150vw;
  }

  &:after {
    content: '';
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background: $white;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
