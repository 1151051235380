%icon {
	/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wil' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon-checkmark {
  content: "\e915";
}
%icon-arrow-down {
  content: "\e900";
}
%icon-arrow-left {
  content: "\e901";
}
%icon-arrow-right {
  content: "\e902";
}
%icon-arrow-up {
  content: "\e903";
}
%icon-close {
  content: "\e904";
}
%icon-email {
  content: "\e905";
}
%icon-facebook {
  content: "\e906";
}
%icon-fact-alter-ego {
  content: "\e907";
}
%icon-fact-favorite-quote {
  content: "\e908";
}
%icon-fact-focus-area {
  content: "\e909";
}
%icon-fact-giving-back {
  content: "\e90a";
}
%icon-fact-global-citizenship {
  content: "\e90b";
}
%icon-fact-hidden-talent {
  content: "\e90c";
}
%icon-fact-on-the-weekend {
  content: "\e90d";
}
%icon-linkedin {
  content: "\e90e";
}
%icon-long-arrow-down {
  content: "\e90f";
}
%icon-long-arrow-left {
  content: "\e910";
}
%icon-long-arrow-right {
  content: "\e911";
}
%icon-long-arrow-up {
  content: "\e912";
}
%icon-menu {
  content: "\e913";
}
%icon-twitter {
  content: "\e914";
}
