@import 'styles/_sass-includes.scss';

.approach__bullet__list{
  li::before {
    content: "- "
  };
}

.approach__lottie__box {
  width: 100%;
  position: relative;
  top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  @include breakpoint($mobile-lg) {
    max-height: 800px;
    margin-bottom: -40px;
  }
  @include breakpoint($mobile-md) {
    max-height: 500px;
    margin-bottom: -20px;
  }
  @include breakpoint($mobile-sm) {
    max-height: 400px;
    margin-bottom: -10px;
  }
  @include breakpoint($mobile-xsm) {
    max-height: 320px;
    margin-bottom: 0px;
  }
}

.abcd {
  transform: translate(0%, -25%);
}

.approach__lottie__adjust {
  width: 200%;
  // height: 480px;
  // overflow: hidden;
  margin-bottom: -200px;
  transform: translate(-25%, 0%);
  @include breakpoint($tablet-lg) {
    margin-bottom: -80px;
  }
  @include breakpoint($tablet-sm) {
    margin-bottom: 40px;
  }
  @include breakpoint($mobile-lg){
    margin-bottom: -640px;
  }
  @include breakpoint($mobile-md-plus){
    margin-bottom: -520px;
  }
  @include breakpoint($mobile-md){
    margin-bottom: -460px;
  }
  @include breakpoint($mobile-sm){
    margin-bottom: -360px;
  }
  @include breakpoint($mobile-xsm){
    margin-bottom: -180px;
  }
}

.approach__japan__base {
  position: relative;
  @include breakpoint($mobile-lg) {
    margin-top: 40px;
    margin-bottom: 440px;
  }
  @include breakpoint($mobile-md) {
    margin-bottom: 200px;
  }
  @include breakpoint($mobile-sm) {
    margin-bottom: 120px;
  }
}

.approach__japan__image {
  max-width: 1000px;
  width: 300%;
  position: absolute;
  top: 0%;
  left: -100%;
  transform: translate(0px, -40%);
  z-index: -1;
  opacity: 0.5;
  // media queries
  @include breakpoint($tablet-lg) {
    // transform: translate(-140px, -200px);
  }
  @include breakpoint($tablet-md) {
    // transform: translate(-160px, -200px);
  }
  @include breakpoint($tablet-sm) {
    // width: 860px;
    // transform: translate(-120px, -180px);
  }
  @include breakpoint($mobile-lg) {
    max-width: none;
    width: 200%;
    top: 0;
    left: 0;
    transform: translate(-25%, -25%);
    // width: 200%;
    // top: 0;
    // left: 0;
    // transform: translate(-25%, -18%);
  }
  @include breakpoint($mobile-md) {
    // transform: translate(-25%, 22%);
  }
  @include breakpoint($mobile-sm) {
    // transform: translate(-25%, 80%);
  }
  @include breakpoint($mobile-xsm) {
    // transform: translate(-25%, 130%);
  }
}

.approach__japan__circle {
  border-radius: 50%;
  background-color: $primary;
  max-width: 320px;
  width: 100%;
  // height: 300px;
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translate(0, 0);

  &:before{
    content: '';
    display: block;
    padding-top: 100%;
  }

  // media queries
  @include breakpoint($tablet-lg) {
    // width: 240px;
    // height: 240px;
    // transform: translate( 0px, 80px);
  }
  @include breakpoint($tablet-md) {

  }
  @include breakpoint($tablet-sm) {
    // width: 200px;
    // height: 200px;
    // transform: translate( 0px, 80px);
  }
  @include breakpoint($mobile-lg) {
    max-width: none;
    width: 80%;
    left: 10%;
    // width: 280px;
    // height: 280px;
    // left: 50%;
    // // width: 200px;
    // // height: 200px;
    // // top: 0px;
    transform: translate(0px, 10%);
  }
  @include breakpoint($mobile-md) {
    // width: 280px;
    // height: 280px;
    // top: 0px;
    // transform: translate(80px, 520px);
  }
  @include breakpoint($mobile-sm) {
    // width: 240px;
    // height: 240px;
    // top: 0px;
    // transform: translate(40px, 720px);
  }
  @include breakpoint($mobile-xsm) {
    // transform: translate(20px, 800px);
  }
}
.approach__adjust__marginBottom {
  margin-bottom: 360px;
  @include breakpoint($mobile-xsm) {
    margin-bottom: 300px;
  }
}

.approach__section__container {

  .btn__container {
    @include breakpoint($mobile-lg) {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      flex-direction: column;
      margin-top: 40px;
      li {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    @include breakpoint($mobile-lg) {
      @include font(bold);
      @include font-size(b1);
      background-color: $primary;
      color: $white;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      z-index: 1000;
      width: 240px;
      justify-content: center;
      padding: 20px 20px 20px 20px;
      cursor: pointer;
      position: relative;
      left: 0;
      margin: 0 auto;
      transition: left 0.2s ease-in-out, color 0.2s ease-in-out;
    
      &:before {
        content: none;
        // width: 35px;
        // height: 2px;
        // background-color: $primary;
        // margin-right: 17px;
        // display: block;
        // top: 2px;
        // position: relative;
        // transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }
    
      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
        // color: $dark;
        // left: -15px;
    
        &:before {
          // background-color: $dark;
          // width: 50px;
        }
      }
    }
  }

}

.approach__cta__text__right {
  text-align: right;
}

.approach__section__text {
  .markdown {
    font-size: 16px;
  }
}