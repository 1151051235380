@import 'styles/_sass-includes.scss';

.whyjapan {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $white;
  display: flex;
  align-items: center;
  z-index: 3;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    background-color: transparent;
    margin-top: 200px;
  }
  @include breakpoint($tablet-sm) {
    margin-top: 60px;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 0;
  }

  .sectiontitle {
    // media queries
    @include breakpoint($tablet-lg) {
      max-width: 420px;
    }

    .lang-jp &.typ--jp{
      font-size: 32px;
      line-height: 1.4;
      font-weight: 600;
    }

  }
}

.whyjapan__country {
  position: absolute;
  top: -170px;
  left: -110px;
  transform: translate(-100px, -220px);
  z-index: -1;
  opacity: 0.5;
  // media queries
  @include breakpoint($tablet-lg) {
    width: 700px;
    top: 0;
    left: 0;
  }
  @include breakpoint($mobile-lg) {
    top: 110px;
  }
}

.whyjapan__circle {
  border-radius: 50%;
  background-color: $primary;
  width: 465px;
  height: 465px;
  z-index: -1;
  position: fixed;
  top: 50%;
  transform: translate(110px, -50%);
  // media queries
  @include breakpoint($tablet-lg) {
    position: absolute;
    width: 400px;
    height: 400px;
    top: 80px;
    transform: translate(-40px, - 50%);
  }
  @include breakpoint($mobile-lg) {
    width: 260px;
    height: 260px;
    top: 40px;
  }
}

.whyjapan__background  {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  display: flex;
  align-items: center;

  .row {
    z-index: 1;
  }
}
