%theme--dark {
  color: $white;

  .sectiontitle { border-color: $white; }

  a {
    color: $white;

    &:hover { color: $primary; }
  }
}
