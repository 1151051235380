@import '~styles/_sass-includes.scss';

.article {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background-color: $white;
}

.article__head {
  background-color: $offwhite;
  margin-bottom: 120px;
  padding-top: 60px;
  position: relative;
  // media queries
  @include breakpoint($tablet-md) {
    margin-bottom: 40px;
  }
}

.article__meta {
  padding-top: 10px;
  margin-top: 60px;
  border-top: 2px solid $black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.article__flex {
  // media queries
  @include breakpoint($tablet-md) {
    display: flex;
    flex-direction: column;
  }
}

.article__author__link {
  color: $dark;
  &:hover {
    color: $primary;
  }
}

.article__title {
  // media queries
  @include breakpoint($tablet-md) {
    justify-self: flex-end;
  }

  .lang-jp & h1 {
    font-size: 32px;
    line-height: 1.4;
  }
}

.article__image {
  // media queries
  @include breakpoint($tablet-md) {
    justify-self: flex-start;
    align-self: center;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative;
  }

  img {
    position: absolute;
    right: 0;
    bottom: -40px;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    object-fit: cover;
    // media queries
    @include breakpoint($desktop-sm) {
      width: 300px;
      height: 300px;
    }
    @include breakpoint($tablet-md) {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.article__social {
  z-index: 10;
  position: relative;

  ul {
    display: inline-flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 5px;
    // media queries
    @include breakpoint($tablet-md) {
      flex-direction: row;
      padding: 0;
      margin: 0;
      display: flex;
      background-color: transparent;
    }
  }

  li {
    display: inline-flex;
    // media queries
    @include breakpoint($tablet-md) {
      margin: 0 25px 30px 0;
    }
  }

  a {
    color: $black;

    &:hover {
      color: $primary;
    }
  }
}

.markdown {
  .markdown__img-container img {
    position: absolute;
    left: -600px;
    top: -100px;
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    // media queries
    @include breakpoint($desktop-sm) {
      left: -500px;
    }
    @include breakpoint($tablet-md) {
      position: relative;
      left: -20px;
      top: 0;
      width: 100vw;
      height: 100vw;
      max-width: 400px;
      max-height: 400px;
      margin: 20px 0;
    }
    @include breakpoint($mobile-lg) {
      left: -50px;
    }
  }

  .markdown__blockquote-container blockquote {
    @include font-size(h2);
    position: relative;
    left: -80px;
    margin: 50px 0;
    color: $primary;
    // media queries
    @include breakpoint($tablet-md) {
      @include font-size(h3);
      left: 0;
    }
    @include breakpoint($mobile-sm) {
      @include font-size(h4);
    }
  }

  figure.markdown__img-container-inner {
    padding: 20px 0 20px 0;

    figcaption {
      font-size: .7em;
      opacity: 0.7;
    }
  }
}
