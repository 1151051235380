.header__wrapper {
  position: relative;
  width: 100%;
  height: 110px;
  display: block; }
  @media only screen and (max-width: 767px) {
    .header__wrapper {
      height: 70px; } }

.header {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  position: fixed;
  width: 100%;
  z-index: 5;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out; }
  @media only screen and (max-width: 767px) {
    .header {
      height: 80px; } }
  @media only screen and (max-width: 400px) {
    .header {
      padding-left: 25px;
      padding-right: 25px; } }
  .header img {
    margin-top: -30px;
    width: 70px; }
    @media only screen and (max-width: 767px) {
      .header img {
        width: 50px; } }

.header__links {
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  opacity: 1;
  transition: 1s; }
  .header__links a {
    color: #001928;
    position: relative;
    margin-top: 4px; }
    .header__links a:hover {
      color: #FF626F; }
  .header__links li {
    font-size: 18px;
    line-height: 1.9;
    margin-left: 20px; }
    .header__links li:last-of-type {
      margin-right: 40px; }
  .header__links .is-active:after {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #FF626F;
    position: absolute;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .header__links .is-shown {
    opacity: 1;
    pointer-events: auto;
    transition: 0.5s; }
  .header__links .is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s; }

.languageselector {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: #FF626F;
  word-break: break-all;
  cursor: pointer;
  border-top: 2px solid #001928;
  padding-top: 20px;
  width: 22px;
  text-align: center;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  z-index: 200; }
  @media only screen and (max-width: 767px) {
    .languageselector {
      position: relative;
      border-color: #FFFFFF;
      right: auto;
      bottom: auto;
      display: block; } }
  @media only screen and (min-width: 767px) {
    .languageselector:hover {
      -webkit-transform: scale(1.08);
              transform: scale(1.08); } }
  .languageselector.centered {
    position: relative;
    border-color: #FFFFFF;
    right: auto;
    bottom: auto;
    display: block; }

.languageselector__character {
  line-height: 1.1;
  margin-top: -3px; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol,
ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  cursor: pointer; }

img {
  max-width: 100%;
  display: inline-block; }

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q,
blockquote {
  quotes: none; }

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: ''; }

a img {
  border: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

button {
  background: none;
  border: 0;
  padding: 0;
  box-shadow: none;
  font-size: inherit; }

hr {
  clear: both; }

* {
  box-sizing: border-box; }

input,
textarea {
  border-radius: 0; }

*,
*:after,
*:before {
  outline: none; }

html,
body {
  width: 100%; }

.row {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto; }

.full-bleed, .row, .row--half {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px; }
  @media only screen and (max-width: 991px) {
    .full-bleed, .row, .row--half {
      padding-left: 60px;
      padding-right: 60px; } }
  @media only screen and (max-width: 767px) {
    .full-bleed, .row, .row--half {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 400px) {
    .full-bleed, .row, .row--half {
      padding-left: 25px;
      padding-right: 25px; } }

.full-bleed {
  width: calc(100% + 200px);
  margin-left: -100px; }
  @media only screen and (max-width: 991px) {
    .full-bleed {
      width: calc(100% + 120px);
      margin-left: -60px; } }
  @media only screen and (max-width: 767px) {
    .full-bleed {
      width: calc(100% + 80px);
      margin-left: -40px; } }
  @media only screen and (max-width: 400px) {
    .full-bleed {
      width: calc(100% + 50px);
      margin-left: -25px; } }

.cf, .row {
  clear: both; }
  .cf:after, .row:after {
    clear: both; }
  .cf:before, .row:before, .cf:after, .row:after {
    content: '';
    display: table; }

.theme--dark {
  color: #FFFFFF; }
  .theme--dark .sectiontitle {
    border-color: #FFFFFF; }
  .theme--dark a {
    color: #FFFFFF; }
    .theme--dark a:hover {
      color: #FF626F; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wil' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

body,
.typ--default {
  font-size: 16px;
  line-height: 1.875; }

h1,
.typ--h1 {
  font-size: 50px;
  line-height: 1.08; }

h2,
.typ--h2 {
  font-size: 38px;
  line-height: 1.15789474; }

h3,
.typ--h3 {
  font-size: 30px;
  line-height: 1.27777778; }

h4,
.typ--h4 {
  font-size: 22px;
  line-height: 1.36363636; }

h5,
.typ--h5 {
  font-size: 20px;
  line-height: 1.9; }

h6,
.typ--h6 {
  font-size: 18px;
  line-height: 1.9; }

.lang-jp h1.typ--jp,
.lang-jp .typ--h1.typ--jp {
  font-size: 50px;
  line-height: 1.08; }

.lang-jp h2.typ--jp,
.lang-jp .typ--h2.typ--jp {
  font-size: 30px;
  line-height: 1.3; }

.lang-jp h3.typ--jp,
.lang-jp .typ--h3.typ--jp {
  font-size: 30px;
  line-height: 1.27777778; }

.lang-jp h4.typ--jp,
.lang-jp .typ--h4.typ--jp {
  font-size: 22px;
  line-height: 1.36363636; }

.lang-jp h5.typ--jp,
.lang-jp .typ--h5.typ--jp {
  font-size: 16px;
  line-height: 1.6; }

.lang-jp .typ--b1.typ--jp {
  font-size: 14px;
  line-height: 1.6; }

.typ--b1 {
  font-size: 16px;
  line-height: 1.875; }

.typ--b2 {
  font-size: 14px;
  line-height: 1.57142857; }

.typ--b3 {
  font-size: 10px;
  line-height: 2.4; }

.typ--caps {
  text-transform: uppercase; }

.typ--titlecase {
  text-transform: capitalize; }

.typ--smallcaps {
  text-transform: lowercase;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps; }

.typ--nowrap {
  white-space: nowrap; }

.typ--pointer {
  cursor: pointer; }

.typ--light {
  font-family: sofia-pro, sans-serif;
  font-weight: 300;
  font-style: normal; }

.typ--regular {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal; }

.typ--medium {
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-style: normal; }

.typ--bold {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal; }

strong {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal; }

p {
  margin-bottom: 10px; }

a {
  text-decoration: none; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.6em;
  padding-left: 30px; }

blockquote {
  padding-left: 30px;
  border-left: 10px solid #FF626F; }

.typ--underline {
  text-decoration: underline; }

.typ--actionable {
  cursor: pointer; }

.typ--truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; }

.typ--center {
  text-align: center; }

.typ--left {
  text-align: left; }

.typ--right {
  text-align: right; }

@font-face {
  font-family: 'wil';
  src: url(/static/media/wil.cfb31714.eot);
  src: url(/static/media/wil.cfb31714.eot#iefix) format("embedded-opentype"), url(/static/media/wil.82f11599.ttf) format("truetype"), url(/static/media/wil.7b1f554f.woff) format("woff"), url(/static/media/wil.02029ad1.svg#wil) format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-checkmark:before {
  content: "\E915"; }

.icon-arrow-down:before {
  content: "\E900"; }

.icon-arrow-left:before {
  content: "\E901"; }

.icon-arrow-right:before {
  content: "\E902"; }

.icon-arrow-up:before {
  content: "\E903"; }

.icon-close:before {
  content: "\E904"; }

.icon-email:before {
  content: "\E905"; }

.icon-facebook:before {
  content: "\E906"; }

.icon-fact-alter-ego:before {
  content: "\E907"; }

.icon-fact-favorite-quote:before {
  content: "\E908"; }

.icon-fact-focus-area:before {
  content: "\E909"; }

.icon-fact-giving-back:before {
  content: "\E90A"; }

.icon-fact-global-citizenship:before {
  content: "\E90B"; }

.icon-fact-hidden-talent:before {
  content: "\E90C"; }

.icon-fact-on-the-weekend:before {
  content: "\E90D"; }

.icon-linkedin:before {
  content: "\E90E"; }

.icon-long-arrow-down:before {
  content: "\E90F"; }

.icon-long-arrow-left:before {
  content: "\E910"; }

.icon-long-arrow-right:before {
  content: "\E911"; }

.icon-long-arrow-up:before {
  content: "\E912"; }

.icon-menu:before {
  content: "\E913"; }

.icon-twitter:before {
  content: "\E914"; }

.disable-scroll {
  overflow: hidden; }

.layout--left {
  float: left !important; }

.layout--right {
  float: right !important; }

.layout--relative {
  position: relative; }

.layout--block {
  display: block; }

.pagecontent {
  min-height: calc(100vh - 110px);
  position: relative; }
  @media only screen and (max-width: 767px) {
    .pagecontent {
      min-height: calc(100vh - 80px); } }

.scroller {
  position: fixed;
  height: 100vh;
  padding-top: 110px;
  width: 100vw;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FFFFFF;
  z-index: 4;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (max-width: 767px) {
    .scroller {
      padding-top: 60px; } }

.row {
  width: 100%;
  position: relative;
  clear: both;
  float: none; }

.row--half {
  max-width: calc(1440px / 2);
  margin: 0; }

.gridspacer {
  height: 1px; }

.col--1 {
  width: calc(100% * 1 / 12 - 40px * (1 - 1 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--1:last-of-type {
    margin-right: 0; }

.col--2 {
  width: calc(100% * 2 / 12 - 40px * (1 - 2 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--2:last-of-type {
    margin-right: 0; }

.col--3 {
  width: calc(100% * 3 / 12 - 40px * (1 - 3 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--3:last-of-type {
    margin-right: 0; }

.col--4 {
  width: calc(100% * 4 / 12 - 40px * (1 - 4 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--4:last-of-type {
    margin-right: 0; }

.col--5 {
  width: calc(100% * 5 / 12 - 40px * (1 - 5 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--5:last-of-type {
    margin-right: 0; }

.col--6 {
  width: calc(100% * 6 / 12 - 40px * (1 - 6 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--6:last-of-type {
    margin-right: 0; }

.col--7 {
  width: calc(100% * 7 / 12 - 40px * (1 - 7 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--7:last-of-type {
    margin-right: 0; }

.col--8 {
  width: calc(100% * 8 / 12 - 40px * (1 - 8 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--8:last-of-type {
    margin-right: 0; }

.col--9 {
  width: calc(100% * 9 / 12 - 40px * (1 - 9 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--9:last-of-type {
    margin-right: 0; }

.col--10 {
  width: calc(100% * 10 / 12 - 40px * (1 - 10 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--10:last-of-type {
    margin-right: 0; }

.col--11 {
  width: calc(100% * 11 / 12 - 40px * (1 - 11 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--11:last-of-type {
    margin-right: 0; }

.col--12 {
  width: calc(100% * 12 / 12 - 40px * (1 - 12 / 12) - 0.01px);
  float: left;
  margin-right: 40px; }
  .col--12:last-of-type {
    margin-right: 0; }

.col--last {
  margin-right: 0; }

.col--center {
  margin-left: auto;
  margin-right: auto !important;
  float: none; }

.m0 {
  margin: 00px; }

.mt0 {
  margin-top: 00px; }

.mr0 {
  margin-right: 00px; }

.mb0 {
  margin-bottom: 00px; }

.mb-0 {
  margin-bottom: 00px; }

.ml0 {
  margin-left: 00px; }

.mx0 {
  margin-left: 00px;
  margin-right: 00px; }

.my0 {
  margin-top: 00px;
  margin-bottom: 00px; }

.p0 {
  padding: 00px; }

.pt0 {
  padding-top: 00px; }

.pr0 {
  padding-right: 00px; }

.pb0 {
  padding-bottom: 00px; }

.pb-0 {
  padding-bottom: 00px; }

.pl0 {
  padding-left: 00px; }

.px0 {
  padding-left: 00px;
  padding-right: 00px; }

.py0 {
  padding-top: 00px;
  padding-bottom: 00px; }

.m1 {
  margin: 10px; }

.mt1 {
  margin-top: 10px; }

.mr1 {
  margin-right: 10px; }

.mb1 {
  margin-bottom: 10px; }

.mb-1 {
  margin-bottom: -10px; }

.ml1 {
  margin-left: 10px; }

.mx1 {
  margin-left: 10px;
  margin-right: 10px; }

.my1 {
  margin-top: 10px;
  margin-bottom: 10px; }

.p1 {
  padding: 10px; }

.pt1 {
  padding-top: 10px; }

.pr1 {
  padding-right: 10px; }

.pb1 {
  padding-bottom: 10px; }

.pb-1 {
  padding-bottom: -10px; }

.pl1 {
  padding-left: 10px; }

.px1 {
  padding-left: 10px;
  padding-right: 10px; }

.py1 {
  padding-top: 10px;
  padding-bottom: 10px; }

.m2 {
  margin: 20px; }

.mt2 {
  margin-top: 20px; }

.mr2 {
  margin-right: 20px; }

.mb2 {
  margin-bottom: 20px; }

.mb-2 {
  margin-bottom: -20px; }

.ml2 {
  margin-left: 20px; }

.mx2 {
  margin-left: 20px;
  margin-right: 20px; }

.my2 {
  margin-top: 20px;
  margin-bottom: 20px; }

.p2 {
  padding: 20px; }

.pt2 {
  padding-top: 20px; }

.pr2 {
  padding-right: 20px; }

.pb2 {
  padding-bottom: 20px; }

.pb-2 {
  padding-bottom: -20px; }

.pl2 {
  padding-left: 20px; }

.px2 {
  padding-left: 20px;
  padding-right: 20px; }

.py2 {
  padding-top: 20px;
  padding-bottom: 20px; }

.m3 {
  margin: 30px; }

.mt3 {
  margin-top: 30px; }

.mr3 {
  margin-right: 30px; }

.mb3 {
  margin-bottom: 30px; }

.mb-3 {
  margin-bottom: -30px; }

.ml3 {
  margin-left: 30px; }

.mx3 {
  margin-left: 30px;
  margin-right: 30px; }

.my3 {
  margin-top: 30px;
  margin-bottom: 30px; }

.p3 {
  padding: 30px; }

.pt3 {
  padding-top: 30px; }

.pr3 {
  padding-right: 30px; }

.pb3 {
  padding-bottom: 30px; }

.pb-3 {
  padding-bottom: -30px; }

.pl3 {
  padding-left: 30px; }

.px3 {
  padding-left: 30px;
  padding-right: 30px; }

.py3 {
  padding-top: 30px;
  padding-bottom: 30px; }

.m4 {
  margin: 40px; }

.mt4 {
  margin-top: 40px; }

.mr4 {
  margin-right: 40px; }

.mb4 {
  margin-bottom: 40px; }

.mb-4 {
  margin-bottom: -40px; }

.ml4 {
  margin-left: 40px; }

.mx4 {
  margin-left: 40px;
  margin-right: 40px; }

.my4 {
  margin-top: 40px;
  margin-bottom: 40px; }

.p4 {
  padding: 40px; }

.pt4 {
  padding-top: 40px; }

.pr4 {
  padding-right: 40px; }

.pb4 {
  padding-bottom: 40px; }

.pb-4 {
  padding-bottom: -40px; }

.pl4 {
  padding-left: 40px; }

.px4 {
  padding-left: 40px;
  padding-right: 40px; }

.py4 {
  padding-top: 40px;
  padding-bottom: 40px; }

.m5 {
  margin: 50px; }

.mt5 {
  margin-top: 50px; }

.mr5 {
  margin-right: 50px; }

.mb5 {
  margin-bottom: 50px; }

.mb-5 {
  margin-bottom: -50px; }

.ml5 {
  margin-left: 50px; }

.mx5 {
  margin-left: 50px;
  margin-right: 50px; }

.my5 {
  margin-top: 50px;
  margin-bottom: 50px; }

.p5 {
  padding: 50px; }

.pt5 {
  padding-top: 50px; }

.pr5 {
  padding-right: 50px; }

.pb5 {
  padding-bottom: 50px; }

.pb-5 {
  padding-bottom: -50px; }

.pl5 {
  padding-left: 50px; }

.px5 {
  padding-left: 50px;
  padding-right: 50px; }

.py5 {
  padding-top: 50px;
  padding-bottom: 50px; }

.m6 {
  margin: 60px; }

.mt6 {
  margin-top: 60px; }

.mr6 {
  margin-right: 60px; }

.mb6 {
  margin-bottom: 60px; }

.mb-6 {
  margin-bottom: -60px; }

.ml6 {
  margin-left: 60px; }

.mx6 {
  margin-left: 60px;
  margin-right: 60px; }

.my6 {
  margin-top: 60px;
  margin-bottom: 60px; }

.p6 {
  padding: 60px; }

.pt6 {
  padding-top: 60px; }

.pr6 {
  padding-right: 60px; }

.pb6 {
  padding-bottom: 60px; }

.pb-6 {
  padding-bottom: -60px; }

.pl6 {
  padding-left: 60px; }

.px6 {
  padding-left: 60px;
  padding-right: 60px; }

.py6 {
  padding-top: 60px;
  padding-bottom: 60px; }

.m7 {
  margin: 70px; }

.mt7 {
  margin-top: 70px; }

.mr7 {
  margin-right: 70px; }

.mb7 {
  margin-bottom: 70px; }

.mb-7 {
  margin-bottom: -70px; }

.ml7 {
  margin-left: 70px; }

.mx7 {
  margin-left: 70px;
  margin-right: 70px; }

.my7 {
  margin-top: 70px;
  margin-bottom: 70px; }

.p7 {
  padding: 70px; }

.pt7 {
  padding-top: 70px; }

.pr7 {
  padding-right: 70px; }

.pb7 {
  padding-bottom: 70px; }

.pb-7 {
  padding-bottom: -70px; }

.pl7 {
  padding-left: 70px; }

.px7 {
  padding-left: 70px;
  padding-right: 70px; }

.py7 {
  padding-top: 70px;
  padding-bottom: 70px; }

.m8 {
  margin: 80px; }

.mt8 {
  margin-top: 80px; }

.mr8 {
  margin-right: 80px; }

.mb8 {
  margin-bottom: 80px; }

.mb-8 {
  margin-bottom: -80px; }

.ml8 {
  margin-left: 80px; }

.mx8 {
  margin-left: 80px;
  margin-right: 80px; }

.my8 {
  margin-top: 80px;
  margin-bottom: 80px; }

.p8 {
  padding: 80px; }

.pt8 {
  padding-top: 80px; }

.pr8 {
  padding-right: 80px; }

.pb8 {
  padding-bottom: 80px; }

.pb-8 {
  padding-bottom: -80px; }

.pl8 {
  padding-left: 80px; }

.px8 {
  padding-left: 80px;
  padding-right: 80px; }

.py8 {
  padding-top: 80px;
  padding-bottom: 80px; }

.m9 {
  margin: 90px; }

.mt9 {
  margin-top: 90px; }

.mr9 {
  margin-right: 90px; }

.mb9 {
  margin-bottom: 90px; }

.mb-9 {
  margin-bottom: -90px; }

.ml9 {
  margin-left: 90px; }

.mx9 {
  margin-left: 90px;
  margin-right: 90px; }

.my9 {
  margin-top: 90px;
  margin-bottom: 90px; }

.p9 {
  padding: 90px; }

.pt9 {
  padding-top: 90px; }

.pr9 {
  padding-right: 90px; }

.pb9 {
  padding-bottom: 90px; }

.pb-9 {
  padding-bottom: -90px; }

.pl9 {
  padding-left: 90px; }

.px9 {
  padding-left: 90px;
  padding-right: 90px; }

.py9 {
  padding-top: 90px;
  padding-bottom: 90px; }

.m10 {
  margin: 100px; }

.mt10 {
  margin-top: 100px; }

.mr10 {
  margin-right: 100px; }

.mb10 {
  margin-bottom: 100px; }

.mb-10 {
  margin-bottom: -100px; }

.ml10 {
  margin-left: 100px; }

.mx10 {
  margin-left: 100px;
  margin-right: 100px; }

.my10 {
  margin-top: 100px;
  margin-bottom: 100px; }

.p10 {
  padding: 100px; }

.pt10 {
  padding-top: 100px; }

.pr10 {
  padding-right: 100px; }

.pb10 {
  padding-bottom: 100px; }

.pb-10 {
  padding-bottom: -100px; }

.pl10 {
  padding-left: 100px; }

.px10 {
  padding-left: 100px;
  padding-right: 100px; }

.py10 {
  padding-top: 100px;
  padding-bottom: 100px; }

.m11 {
  margin: 110px; }

.mt11 {
  margin-top: 110px; }

.mr11 {
  margin-right: 110px; }

.mb11 {
  margin-bottom: 110px; }

.mb-11 {
  margin-bottom: -110px; }

.ml11 {
  margin-left: 110px; }

.mx11 {
  margin-left: 110px;
  margin-right: 110px; }

.my11 {
  margin-top: 110px;
  margin-bottom: 110px; }

.p11 {
  padding: 110px; }

.pt11 {
  padding-top: 110px; }

.pr11 {
  padding-right: 110px; }

.pb11 {
  padding-bottom: 110px; }

.pb-11 {
  padding-bottom: -110px; }

.pl11 {
  padding-left: 110px; }

.px11 {
  padding-left: 110px;
  padding-right: 110px; }

.py11 {
  padding-top: 110px;
  padding-bottom: 110px; }

.m12 {
  margin: 120px; }

.mt12 {
  margin-top: 120px; }

.mr12 {
  margin-right: 120px; }

.mb12 {
  margin-bottom: 120px; }

.mb-12 {
  margin-bottom: -120px; }

.ml12 {
  margin-left: 120px; }

.mx12 {
  margin-left: 120px;
  margin-right: 120px; }

.my12 {
  margin-top: 120px;
  margin-bottom: 120px; }

.p12 {
  padding: 120px; }

.pt12 {
  padding-top: 120px; }

.pr12 {
  padding-right: 120px; }

.pb12 {
  padding-bottom: 120px; }

.pb-12 {
  padding-bottom: -120px; }

.pl12 {
  padding-left: 120px; }

.px12 {
  padding-left: 120px;
  padding-right: 120px; }

.py12 {
  padding-top: 120px;
  padding-bottom: 120px; }

.m13 {
  margin: 130px; }

.mt13 {
  margin-top: 130px; }

.mr13 {
  margin-right: 130px; }

.mb13 {
  margin-bottom: 130px; }

.mb-13 {
  margin-bottom: -130px; }

.ml13 {
  margin-left: 130px; }

.mx13 {
  margin-left: 130px;
  margin-right: 130px; }

.my13 {
  margin-top: 130px;
  margin-bottom: 130px; }

.p13 {
  padding: 130px; }

.pt13 {
  padding-top: 130px; }

.pr13 {
  padding-right: 130px; }

.pb13 {
  padding-bottom: 130px; }

.pb-13 {
  padding-bottom: -130px; }

.pl13 {
  padding-left: 130px; }

.px13 {
  padding-left: 130px;
  padding-right: 130px; }

.py13 {
  padding-top: 130px;
  padding-bottom: 130px; }

.m14 {
  margin: 140px; }

.mt14 {
  margin-top: 140px; }

.mr14 {
  margin-right: 140px; }

.mb14 {
  margin-bottom: 140px; }

.mb-14 {
  margin-bottom: -140px; }

.ml14 {
  margin-left: 140px; }

.mx14 {
  margin-left: 140px;
  margin-right: 140px; }

.my14 {
  margin-top: 140px;
  margin-bottom: 140px; }

.p14 {
  padding: 140px; }

.pt14 {
  padding-top: 140px; }

.pr14 {
  padding-right: 140px; }

.pb14 {
  padding-bottom: 140px; }

.pb-14 {
  padding-bottom: -140px; }

.pl14 {
  padding-left: 140px; }

.px14 {
  padding-left: 140px;
  padding-right: 140px; }

.py14 {
  padding-top: 140px;
  padding-bottom: 140px; }

.m15 {
  margin: 150px; }

.mt15 {
  margin-top: 150px; }

.mr15 {
  margin-right: 150px; }

.mb15 {
  margin-bottom: 150px; }

.mb-15 {
  margin-bottom: -150px; }

.ml15 {
  margin-left: 150px; }

.mx15 {
  margin-left: 150px;
  margin-right: 150px; }

.my15 {
  margin-top: 150px;
  margin-bottom: 150px; }

.p15 {
  padding: 150px; }

.pt15 {
  padding-top: 150px; }

.pr15 {
  padding-right: 150px; }

.pb15 {
  padding-bottom: 150px; }

.pb-15 {
  padding-bottom: -150px; }

.pl15 {
  padding-left: 150px; }

.px15 {
  padding-left: 150px;
  padding-right: 150px; }

.py15 {
  padding-top: 150px;
  padding-bottom: 150px; }

.m16 {
  margin: 160px; }

.mt16 {
  margin-top: 160px; }

.mr16 {
  margin-right: 160px; }

.mb16 {
  margin-bottom: 160px; }

.mb-16 {
  margin-bottom: -160px; }

.ml16 {
  margin-left: 160px; }

.mx16 {
  margin-left: 160px;
  margin-right: 160px; }

.my16 {
  margin-top: 160px;
  margin-bottom: 160px; }

.p16 {
  padding: 160px; }

.pt16 {
  padding-top: 160px; }

.pr16 {
  padding-right: 160px; }

.pb16 {
  padding-bottom: 160px; }

.pb-16 {
  padding-bottom: -160px; }

.pl16 {
  padding-left: 160px; }

.px16 {
  padding-left: 160px;
  padding-right: 160px; }

.py16 {
  padding-top: 160px;
  padding-bottom: 160px; }

.m17 {
  margin: 170px; }

.mt17 {
  margin-top: 170px; }

.mr17 {
  margin-right: 170px; }

.mb17 {
  margin-bottom: 170px; }

.mb-17 {
  margin-bottom: -170px; }

.ml17 {
  margin-left: 170px; }

.mx17 {
  margin-left: 170px;
  margin-right: 170px; }

.my17 {
  margin-top: 170px;
  margin-bottom: 170px; }

.p17 {
  padding: 170px; }

.pt17 {
  padding-top: 170px; }

.pr17 {
  padding-right: 170px; }

.pb17 {
  padding-bottom: 170px; }

.pb-17 {
  padding-bottom: -170px; }

.pl17 {
  padding-left: 170px; }

.px17 {
  padding-left: 170px;
  padding-right: 170px; }

.py17 {
  padding-top: 170px;
  padding-bottom: 170px; }

.m18 {
  margin: 180px; }

.mt18 {
  margin-top: 180px; }

.mr18 {
  margin-right: 180px; }

.mb18 {
  margin-bottom: 180px; }

.mb-18 {
  margin-bottom: -180px; }

.ml18 {
  margin-left: 180px; }

.mx18 {
  margin-left: 180px;
  margin-right: 180px; }

.my18 {
  margin-top: 180px;
  margin-bottom: 180px; }

.p18 {
  padding: 180px; }

.pt18 {
  padding-top: 180px; }

.pr18 {
  padding-right: 180px; }

.pb18 {
  padding-bottom: 180px; }

.pb-18 {
  padding-bottom: -180px; }

.pl18 {
  padding-left: 180px; }

.px18 {
  padding-left: 180px;
  padding-right: 180px; }

.py18 {
  padding-top: 180px;
  padding-bottom: 180px; }

.m19 {
  margin: 190px; }

.mt19 {
  margin-top: 190px; }

.mr19 {
  margin-right: 190px; }

.mb19 {
  margin-bottom: 190px; }

.mb-19 {
  margin-bottom: -190px; }

.ml19 {
  margin-left: 190px; }

.mx19 {
  margin-left: 190px;
  margin-right: 190px; }

.my19 {
  margin-top: 190px;
  margin-bottom: 190px; }

.p19 {
  padding: 190px; }

.pt19 {
  padding-top: 190px; }

.pr19 {
  padding-right: 190px; }

.pb19 {
  padding-bottom: 190px; }

.pb-19 {
  padding-bottom: -190px; }

.pl19 {
  padding-left: 190px; }

.px19 {
  padding-left: 190px;
  padding-right: 190px; }

.py19 {
  padding-top: 190px;
  padding-bottom: 190px; }

.m20 {
  margin: 200px; }

.mt20 {
  margin-top: 200px; }

.mr20 {
  margin-right: 200px; }

.mb20 {
  margin-bottom: 200px; }

.mb-20 {
  margin-bottom: -200px; }

.ml20 {
  margin-left: 200px; }

.mx20 {
  margin-left: 200px;
  margin-right: 200px; }

.my20 {
  margin-top: 200px;
  margin-bottom: 200px; }

.p20 {
  padding: 200px; }

.pt20 {
  padding-top: 200px; }

.pr20 {
  padding-right: 200px; }

.pb20 {
  padding-bottom: 200px; }

.pb-20 {
  padding-bottom: -200px; }

.pl20 {
  padding-left: 200px; }

.px20 {
  padding-left: 200px;
  padding-right: 200px; }

.py20 {
  padding-top: 200px;
  padding-bottom: 200px; }

body {
  color: #001928;
  background-color: #FFFFFF; }

a,
.typ--link {
  color: #FF626F;
  transition: color 0.1s ease-in-out;
  cursor: pointer; }
  a:hover,
  .typ--link:hover {
    color: #001928; }

.typ--primary {
  color: #FF626F; }

.typ--dark {
  color: #001928; }

.typ--black {
  color: #000000; }

.typ--white {
  color: #FFFFFF; }

.typ--beige {
  color: #F1D6BB; }

.typ--babyblue {
  color: #D7FFFF; }

.typ--grey {
  color: #DDDCD8; }

.typ--greyblue {
  color: #B8E3EC; }

.typ--offwhite {
  color: #FBFBFB; }

.bg--primary {
  background-color: #FF626F; }

.bg--dark {
  background-color: #001928; }

.bg--black {
  background-color: #000000; }

.bg--white {
  background-color: #FFFFFF; }

.bg--beige {
  background-color: #F1D6BB; }

.bg--babyblue {
  background-color: #D7FFFF; }

.bg--grey {
  background-color: #DDDCD8; }

.bg--greyblue {
  background-color: #B8E3EC; }

.bg--offwhite {
  background-color: #FBFBFB; }

.bg-tan {
  background-color: #E5DCCC; }

.bg-light-blue {
  background-color: #C1F1F9; }

.bg-purple {
  background-color: #E8E0F0; }

.bg-medium-blue {
  background-color: #C1DDF2; }

.sectiontitle {
  border-top: 2px solid #001928;
  padding-top: 0.4em;
  margin-bottom: 1em; }

.numberedtitle {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-left: -64px;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .numberedtitle {
      margin-left: 0; } }
  .numberedtitle span {
    font-size: 14px;
    line-height: 1.57142857;
    width: 64px; }
    @media only screen and (max-width: 991px) {
      .numberedtitle span {
        display: none; } }

.circle__shadow {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 98, 111, 0);
  background-color: #FF626F;
  border-radius: 50%; }

.scrollindicator {
  font-size: 38px;
  line-height: 1.15789474;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  z-index: 11; }

.modal__close {
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  top: -25px;
  right: -25px;
  width: 80px;
  height: 80px;
  background-color: #FF626F;
  color: #FFFFFF;
  z-index: 95;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out; }
  .modal__close:hover {
    background-color: #001928; }
  .modal__close [class^="icon-"] {
    font-size: 22px;
    padding-left: 22px;
    padding-bottom: 22px; }

.list--aside {
  border-left: 2px solid #001928;
  padding-left: 30px; }
  @media only screen and (max-width: 767px) {
    .list--aside {
      padding-left: 20px; } }
  .list--aside > li {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 1.57142857;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .list--aside > li {
        margin-bottom: 20px; } }
    .list--aside > li:last-of-type {
      margin-bottom: 0; }

.btn {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 1.875;
  color: #FF626F;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  padding: 10px 10px 10px 0;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: left 0.2s ease-in-out, color 0.2s ease-in-out; }
  .btn:before {
    content: '';
    width: 35px;
    height: 2px;
    background-color: #FF626F;
    margin-right: 17px;
    display: block;
    top: 2px;
    position: relative;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out; }
  .btn:hover {
    color: #001928;
    left: -15px; }
    .btn:hover:before {
      background-color: #001928;
      width: 50px; }

.form__field {
  position: relative;
  padding-bottom: 30px; }

.form__input {
  font-size: 14px;
  line-height: 1.57142857;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0.8em;
  border: 2px solid transparent;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 70px 16px rgba(0, 0, 0, 0.06);
  border-radius: 3px; }
  .form__input.has-error {
    border-color: #FF626F; }
  .form__input[type='file'] {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    background: transparent;
    border: 0; }

textarea.form__input {
  min-height: 180px;
  resize: none; }

.is-disabled {
  pointer-events: none;
  opacity: 0.5; }

.form__label,
.form__label .label {
  font-size: 14px;
  line-height: 1.57142857;
  margin-bottom: 10px;
  display: block; }

.form__error {
  font-size: 14px;
  line-height: 1.57142857;
  text-transform: uppercase;
  color: #FF626F;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.form__sectiontitle {
  margin-bottom: 10px; }

.form__fieldrow {
  margin-bottom: 45px; }

.form__fields {
  position: relative; }

.form__file {
  position: relative; }
  .form__file input[type='file'] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.app {
  width: 100vw;
  overflow: hidden; }

.lang-jp .typ--jp {
  font-family: sofia-pro,'Noto Sans JP', sans-serif !important; }

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; }

.markdown {
  font-size: 18px;
  line-height: 1.9; }
  .markdown .markdown__element-container {
    position: relative;
    display: block; }
  .markdown em {
    font-style: italic; }
  .markdown b {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal; }
  .markdown ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px; }
  .markdown ol li {
    display: block; }
  .markdown pre {
    background-color: rgba(221, 220, 216, 0.5);
    padding: 5px 10px; }
  .markdown table {
    width: 100%;
    border: 1px solid #DDDCD8;
    border-radius: 5px; }
  .markdown thead tr {
    border-bottom: 1px solid #DDDCD8;
    background-color: rgba(221, 220, 216, 0.1);
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.04); }
    .markdown thead tr th {
      padding: 5px 15px; }
  .markdown td {
    padding: 5px 15px; }
  .markdown tbody tr:nth-of-type(1n) {
    background-color: rgba(221, 220, 216, 0.3); }
  .markdown tbody tr:nth-of-type(2n) {
    background-color: #FBFBFB; }
  .markdown tbody tr:first-of-type td {
    padding-top: 10px; }

.whyjapan {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  z-index: 3; }
  @media only screen and (max-width: 1040px) {
    .whyjapan {
      width: 100%;
      height: auto;
      min-height: 100vh;
      position: relative;
      background-color: transparent;
      margin-top: 200px; } }
  @media only screen and (max-width: 840px) {
    .whyjapan {
      margin-top: 60px; } }
  @media only screen and (max-width: 767px) {
    .whyjapan {
      padding-top: 0; } }
  @media only screen and (max-width: 1040px) {
    .whyjapan .sectiontitle {
      max-width: 420px; } }
  .lang-jp .whyjapan .sectiontitle.typ--jp {
    font-size: 32px;
    line-height: 1.4;
    font-weight: 600; }

.whyjapan__country {
  position: absolute;
  top: -170px;
  left: -110px;
  -webkit-transform: translate(-100px, -220px);
          transform: translate(-100px, -220px);
  z-index: -1;
  opacity: 0.5; }
  @media only screen and (max-width: 1040px) {
    .whyjapan__country {
      width: 700px;
      top: 0;
      left: 0; } }
  @media only screen and (max-width: 767px) {
    .whyjapan__country {
      top: 110px; } }

.whyjapan__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 465px;
  height: 465px;
  z-index: -1;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(110px, -50%);
          transform: translate(110px, -50%); }
  @media only screen and (max-width: 1040px) {
    .whyjapan__circle {
      position: absolute;
      width: 400px;
      height: 400px;
      top: 80px;
      -webkit-transform: translate(-40px, -50%);
              transform: translate(-40px, -50%); } }
  @media only screen and (max-width: 767px) {
    .whyjapan__circle {
      width: 260px;
      height: 260px;
      top: 40px; } }

.whyjapan__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  display: flex;
  align-items: center; }
  .whyjapan__background .row {
    z-index: 1; }

.whatwedo {
  z-index: 4;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 150px; }
  @media only screen and (max-width: 1040px) {
    .whatwedo {
      width: 100%;
      height: auto;
      position: relative; } }
  @media only screen and (max-width: 767px) {
    .whatwedo {
      padding-top: 100px; } }
  .whatwedo .sectiontitle {
    margin-bottom: 140px; }
    @media only screen and (max-width: 1040px) {
      .whatwedo .sectiontitle {
        margin-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .whatwedo .sectiontitle {
        margin-bottom: 20px; } }

.whatwedo__block {
  opacity: 0.5;
  transition: 0.3s ease-in-out; }
  @media only screen and (max-width: 1040px) {
    .whatwedo__block {
      opacity: 1; } }
  .whatwedo__block.is-active {
    opacity: 1; }

.whatwedo__window {
  position: fixed;
  left: 50%;
  top: 0;
  height: 100vh;
  width: 50%;
  background-color: #FBFBFB;
  pointer-events: none; }

.whatwedo__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 110px;
  height: 110px;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10; }

.whatwedo__lottie {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; }
  @media only screen and (max-width: 1040px) {
    .whatwedo__lottie {
      position: relative;
      top: 0;
      left: 0;
      -webkit-transform: none;
              transform: none;
      opacity: 1;
      height: 300px;
      width: auto;
      z-index: 1; } }
  @media only screen and (max-width: 1040px) {
    .whatwedo__lottie.lottie-2 > div:not(.lottie__circle) {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      height: calc(100% + 350px) !important; } }
  @media only screen and (max-width: 767px) {
    .whatwedo__lottie.lottie-2 > div:not(.lottie__circle) {
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: auto !important;
      width: calc(100% + 250px) !important; } }
  .whatwedo__lottie.is-active {
    opacity: 1;
    z-index: 11; }

.lottie-1,
.lottie-0 {
  width: 300px;
  height: 308px; }

.lottie-2 {
  width: 750px;
  height: 758px;
  max-width: 100%; }
  @media only screen and (max-width: 1040px) {
    .lottie-2 {
      width: auto; } }

.lottie__circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #FF626F;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.whatwedo {
  z-index: 4;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 150px; }
  @media only screen and (max-width: 1040px) {
    .whatwedo {
      width: 100%;
      height: auto;
      position: relative; } }
  @media only screen and (max-width: 767px) {
    .whatwedo {
      padding-top: 100px; } }
  .whatwedo .sectiontitle {
    margin-bottom: 140px; }
    @media only screen and (max-width: 1040px) {
      .whatwedo .sectiontitle {
        margin-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .whatwedo .sectiontitle {
        margin-bottom: 20px; } }

.whatwedo__block {
  opacity: 0.5;
  transition: 0.3s ease-in-out; }
  @media only screen and (max-width: 1040px) {
    .whatwedo__block {
      opacity: 1; } }
  .whatwedo__block.is-active {
    opacity: 1; }

.whatwedo__window {
  position: fixed;
  left: 50%;
  top: 0;
  height: 100vh;
  width: 50%;
  background-color: #FBFBFB;
  pointer-events: none; }

.whatwedo__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 110px;
  height: 110px;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10; }

.whatwedo__lottie {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }
  @media only screen and (max-width: 1040px) {
    .whatwedo__lottie {
      position: relative;
      top: 0;
      left: 0;
      -webkit-transform: none;
              transform: none;
      opacity: 1;
      height: 300px; } }
  @media only screen and (max-width: 1040px) {
    .whatwedo__lottie.lottie-2 > div:not(.lottie__circle) {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      height: calc(100% + 350px) !important; } }
  @media only screen and (max-width: 767px) {
    .whatwedo__lottie.lottie-2 > div:not(.lottie__circle) {
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: auto !important;
      width: calc(100% + 250px) !important; } }
  .whatwedo__lottie.is-active {
    opacity: 1;
    z-index: 11; }

.lottie-2 {
  width: 750px;
  max-width: 100%; }
  @media only screen and (max-width: 1040px) {
    .lottie-2 {
      width: auto; } }

.lottie__circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #FF626F;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.whatwedo__text {
  width: calc(-13.3px + 66.66%);
  margin-right: 40px;
  float: left; }
  @media only screen and (max-width: 767px) {
    .whatwedo__text {
      width: 100%;
      float: none;
      margin-right: 0; } }

.whatwedo__textwrapper {
  z-index: 10; }

.whatwedo__aside {
  width: calc(-26.7px + 33.33%);
  float: left; }
  @media only screen and (max-width: 767px) {
    .whatwedo__aside {
      width: 100%;
      float: none;
      margin-bottom: 30px; } }

.accordian__content {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.vehicle--carousel .carousel__slide {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px; }
  @media only screen and (max-width: 991px) {
    .vehicle--carousel .carousel__slide {
      padding-left: 60px;
      padding-right: 60px; } }
  @media only screen and (max-width: 767px) {
    .vehicle--carousel .carousel__slide {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 400px) {
    .vehicle--carousel .carousel__slide {
      padding-left: 25px;
      padding-right: 25px; } }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.carousel.show-arrows {
  padding: 0 100px; }

.carousel.show-dots {
  padding-bottom: 40px; }
  .carousel.show-dots .carousel__arrows {
    top: calc(50% - 20px); }

.carousel__arrows {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #FF626F;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .carousel__arrows [class^="icon-"] {
    font-size: 30px; }

.carousel:after {
  content: '';
  margin-bottom: 10px;
  display: block; }

.vehicle--carousel .carousel__slide {
  height: 30vh;
  display: flex;
  align-items: center; }

.carousel__slide {
  width: 100%; }
  .carousel__slide img {
    width: 100%; }

.carousel__caption {
  text-align: center; }

.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1; }
  @media only screen and (max-width: 1040px) {
    .flickity-page-dots {
      text-align: center; } }

.has-caption .flickity-page-dots {
  bottom: -70px; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #001928; }

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #FF626F;
  border-color: #FF626F; }

.ourvalues {
  z-index: 1;
  background-color: transparent;
  width: 100%; }
  @media only screen and (max-width: 1040px) {
    .ourvalues {
      height: auto;
      position: relative;
      min-height: 0; } }
  @media only screen and (max-width: 767px) {
    .ourvalues {
      padding-bottom: 0; } }
  .ourvalues .flickity-viewport {
    overflow: visible;
    z-index: 10; }
  .ourvalues .carousel__slide {
    opacity: 0;
    transition: 0.2s ease-in-out; }
    .ourvalues .carousel__slide.is-selected {
      opacity: 1; }
  .ourvalues .scrollindicator {
    position: relative;
    display: block;
    margin-top: 120px;
    text-align: center; }
  .ourvalues .carousel__slide.is-selected h4 {
    color: #FF626F; }
  .ourvalues .carousel.show-dots {
    padding-bottom: 20px; }

.ourvalues__slide {
  position: relative;
  display: flex;
  align-items: flex-start; }
  .ourvalues__slide span {
    position: absolute;
    left: 0;
    top: 130px;
    z-index: 1; }
  .ourvalues__slide > div {
    width: 50%; }

.ourvalues__title {
  position: relative;
  padding-bottom: 50%; }
  .ourvalues__title h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.ourvalues__circle {
  background-color: #FF626F;
  border-radius: 50%;
  width: 134px;
  height: 134px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5; }
  @media only screen and (max-width: 1040px) {
    .ourvalues__circle {
      width: 100px;
      height: 100px; } }
  @media only screen and (max-width: 767px) {
    .ourvalues__circle {
      width: 80px;
      height: 80px; } }

.ourvalues__sections {
  display: flex; }
  @media only screen and (max-width: 1040px) {
    .ourvalues__sections {
      flex-direction: column; } }

.ourvalues__imgcontainer {
  width: 33.333%; }
  @media only screen and (max-width: 1040px) {
    .ourvalues__imgcontainer {
      width: 350px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .ourvalues__imgcontainer {
      width: 100%; } }

.ourvalues__hero {
  width: 100%;
  padding-bottom: 100%;
  position: relative; }

.ourvalues__img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  @media only screen and (max-width: 1040px) {
    .ourvalues__img {
      position: relative;
      opacity: 1;
      -webkit-transform: none;
              transform: none;
      max-width: 550px; } }
  .ourvalues__img.is-active {
    opacity: 1;
    -webkit-transform: none;
            transform: none; }

.ourvalues__grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin-left: 100px; }
  @media only screen and (max-width: 1040px) {
    .ourvalues__grid {
      margin-left: 0;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch; } }
  .ourvalues__grid > div {
    cursor: pointer;
    width: calc(50% - 40px);
    margin-right: 20px;
    margin-bottom: 10px;
    min-height: 140px; }
    @media only screen and (max-width: 1200px) {
      .ourvalues__grid > div {
        width: 100%;
        margin-right: 0;
        min-height: 0; } }
    @media only screen and (max-width: 1040px) {
      .ourvalues__grid > div {
        width: 300px;
        flex-shrink: 0; } }
  .ourvalues__grid h4 {
    transition: color 0.2s ease-in-out; }

.tabs {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 400px) {
    .tabs {
      width: calc(100% + 50px);
      margin-left: -25px;
      overflow-x: auto;
      padding: 0 25px 25px; } }
  .tabs li {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    padding: 0 2px; }
    .tabs li.is-active {
      border-color: #FF626F; }

.jobpost__body h1, .jobpost__body h2, .jobpost__body h3, .jobpost__body h4, .jobpost__body h5 {
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 40px; }
  .jobpost__body h1:first-of-type, .jobpost__body h2:first-of-type, .jobpost__body h3:first-of-type, .jobpost__body h4:first-of-type, .jobpost__body h5:first-of-type {
    margin-top: 0; }

.jobpost__body ul {
  list-style-type: disc;
  margin-bottom: 30px;
  margin-left: 20px; }

.jobpost__body b {
  font-weight: 700; }

.lang-jp .jobpost__body__other {
  display: none; }

.careers {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media only screen and (max-width: 1040px) {
    .careers {
      padding-top: 40px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .careers {
      padding-top: 20px;
      padding-bottom: 40px; } }

.jobpost {
  background-color: #FBFBFB;
  padding: 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .jobpost {
      padding: 30px; } }

.jobpost__bgmap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: contain;
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.6;
  height: 550px; }

.jobpost__content {
  z-index: 2;
  position: relative; }

@media only screen and (max-width: 1040px) {
  .careers__header {
    padding-top: 0; } }

.careers__header img {
  width: calc(100% + 150px);
  max-width: none;
  position: absolute;
  right: 0;
  top: 120px; }
  @media only screen and (max-width: 1040px) {
    .careers__header img {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block; } }

.link__privacypolicy {
  font-size: 0.8em; }

.oneSecDelay {
  opacity: 0;
  -webkit-animation: fadeIn .5s;
          animation: fadeIn .5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.footer {
  color: #FFFFFF; }
  .footer .sectiontitle {
    border-color: #FFFFFF; }
  .footer a {
    color: #FFFFFF; }
    .footer a:hover {
      color: #FF626F; }

.footer {
  padding-top: 110px;
  padding-bottom: 40px;
  background-image: url(/static/media/footer_bg.3c7450f0.svg);
  background-repeat: no-repeat;
  background-size: 5000px;
  background-position: top center;
  overflow: hidden;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 1040px) {
    .footer {
      padding-top: 60px; } }
  .footer:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #001928; }
    @media only screen and (max-width: 1040px) {
      .footer:after {
        top: 110px;
        z-index: -1; } }
  .footer ul {
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-style: normal; }
    @media only screen and (max-width: 350px) {
      .footer ul {
        font-size: 14px;
        line-height: 1.57142857; } }

*::-ms-backdrop, .footer {
  background-color: #001928; }

/* IE11 */
.footernext {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #FFFFFF; }
  @media only screen and (max-width: 1040px) {
    .footernext {
      justify-content: center;
      margin-bottom: 40px; } }
  .footernext:hover {
    color: #FFFFFF; }

.footernext__cta {
  position: relative;
  z-index: 20;
  top: -20px;
  left: 60px;
  color: #FFFFFF; }
  @media only screen and (max-width: 1040px) {
    .footernext__cta {
      margin-left: -30px;
      left: 40px; } }
  .footernext__cta h1 {
    line-height: 1; }

.footernext__circle {
  width: 160px;
  height: 160px;
  background-color: #FF626F;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1040px) {
    .footernext__circle {
      width: 90px;
      height: 90px; } }
  .footernext__circle [class^="icon-"] {
    font-size: 70px;
    color: #001928; }
    @media only screen and (max-width: 1040px) {
      .footernext__circle [class^="icon-"] {
        font-size: 30px; } }

.footer__col6 {
  width: 50%;
  float: left; }

.footer__address {
  margin-top: 15px; }
  .footer__address a {
    font-size: 10px;
    line-height: 2.4;
    display: block;
    line-height: 1.8; }

.locations {
  background-color: #FFFFFF;
  width: 100%; }
  .locations > .row {
    height: calc(100vh - 470px);
    overflow: hidden;
    min-height: 820px;
    z-index: 1;
    position: relative; }
    @media only screen and (max-width: 840px) {
      .locations > .row {
        height: auto; } }
  .locations .footer {
    margin-top: -40px; }

.locations__map {
  position: absolute;
  right: 0;
  display: flex;
  height: 100vh;
  width: 100vh;
  max-height: 1156px;
  max-width: 1156px;
  pointer-events: none;
  z-index: 1; }
  @media only screen and (max-width: 1040px) {
    .locations__map {
      height: 75vh;
      width: 75vh;
      top: 130px; } }
  @media only screen and (max-width: 840px) {
    .locations__map {
      width: 60vh;
      height: 60vh;
      top: 0;
      position: relative; } }
  @media only screen and (max-width: 767px) {
    .locations__map {
      width: 50vh;
      height: 50vh; } }
  .locations__map img {
    position: absolute;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .locations__map img.fade-in {
      -webkit-transform: none;
              transform: none;
      opacity: 1; }
    .locations__map img.fade-out {
      -webkit-transform: translateX(-400px);
              transform: translateX(-400px);
      opacity: 0; }

.locations__circle {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #FF626F;
  border-radius: 50%;
  z-index: 1000; }
  @media only screen and (max-width: 767px) {
    .locations__circle {
      width: 12px;
      height: 12px; } }

@media only screen and (max-width: 350px) {
  .locations__address p {
    font-size: 14px;
    line-height: 1.57142857; } }

.locations__address p span {
  display: block;
  margin-bottom: 5px; }

.circles {
  position: absolute;
  width: 1440px;
  height: 820px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block; }
  @media only screen and (max-width: 1040px) {
    .circles {
      width: 1024px;
      height: 768px; } }
  @media only screen and (max-width: 991px) {
    .circles {
      width: 768px;
      height: 1024px; } }
  @media only screen and (max-width: 767px) {
    .circles {
      width: 375px;
      height: 667px; } }
  @media only screen and (max-width: 350px) {
    .circles {
      width: 320px;
      height: 568px; } }

.circles__item {
  position: absolute;
  background-color: #E8E0F0;
  border-radius: 50%; }
  .circles__item:nth-of-type(1) {
    background-color: #F1D6BB; }
  .circles__item:nth-of-type(2) {
    background-color: #D7FFFF; }
  .circles__item:nth-of-type(3) {
    background-color: #DDDCD8; }
  .circles__item:nth-of-type(4) {
    background-color: #B8E3EC; }
  .circles__item:nth-of-type(5) {
    background-color: #E8E0F0; }
  .circles__item:nth-of-type(6) {
    background-color: #C1DDF2; }
  .circles__item:nth-of-type(7) {
    background-color: #C1F1F9; }
  .circles__item:nth-of-type(8) {
    background-color: #E5DCCC; }
  .circles__item:nth-of-type(9) {
    background-color: #C1DDF2; }
  .circles__item > div {
    width: 100%;
    height: 100%;
    position: relative; }
  .circles__item img {
    max-width: none;
    width: 100%;
    object-fit: contain; }

.circles__carousel {
  border-radius: 50%;
  overflow: hidden;
  z-index: 10;
  position: relative; }

.circles__caption {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(10deg);
          transform: translate(-50%, -50%) rotate(10deg);
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  pointer-events: none;
  overflow: visible; }
  .circles__caption path {
    fill: transparent; }
  .circles__caption text {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 1.57142857; }
  .circles__caption.circle--left {
    -webkit-transform: translate(-50%, -50%) rotate(-110deg);
            transform: translate(-50%, -50%) rotate(-110deg); }
  @media only screen and (max-width: 840px) {
    .circles__caption {
      width: calc(100% + 30px);
      height: calc(100% + 30px); }
      .circles__caption text {
        font-size: 10px;
        line-height: 2.4; } }

.home {
  position: relative;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1040px) {
    .home {
      flex-direction: column;
      height: auto;
      position: relative; } }

.circle__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 200px;
  position: absolute;
  top: 0;
  z-index: 2;
  pointer-events: none;
  max-width: 320px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (max-width: 1300px) {
    .circle__content {
      padding-top: 75px; } }
  @media only screen and (max-width: 1040px) {
    .circle__content {
      padding-top: 100px; } }
  @media only screen and (max-width: 991px) {
    .circle__content {
      padding-top: 125px; } }
  @media only screen and (max-width: 767px) {
    .circle__content {
      padding-top: 45px;
      max-width: 200px; } }
  .circle__content h1 {
    font-size: 70px;
    line-height: 1; }
    @media only screen and (max-width: 991px) {
      .circle__content h1 {
        font-size: 50px; } }
    @media only screen and (max-width: 767px) {
      .circle__content h1 {
        font-size: 32px; } }
    .lang-jp .circle__content h1 {
      font-size: 70px;
      line-height: 1; }
      @media only screen and (max-width: 991px) {
        .lang-jp .circle__content h1 {
          font-size: 50px; } }
      @media only screen and (max-width: 767px) {
        .lang-jp .circle__content h1 {
          font-size: 32px; } }
  .lang-jp .circle__content h4 {
    font-size: 20px; }
    @media only screen and (max-width: 991px) {
      .lang-jp .circle__content h4 {
        font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      .lang-jp .circle__content h4 {
        font-size: 14px; } }

.herocircle {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 735px;
  height: 735px; }
  @media only screen and (max-width: 1300px) {
    .herocircle {
      width: 580px;
      height: 580px; } }
  @media only screen and (max-width: 1040px) {
    .herocircle {
      width: 550px;
      height: 550px; } }
  @media only screen and (max-width: 767px) {
    .herocircle {
      width: 323px;
      height: 323px; } }
  @media only screen and (max-width: 350px) {
    .herocircle {
      width: 277px;
      height: 277px; } }
  .herocircle:not(.is-animating) .circle {
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out; }
    @media only screen and (min-width: 991px) {
      .herocircle:not(.is-animating) .circle:hover {
        width: 760px !important;
        height: 760px !important; } }
  @media only screen and (min-width: 991px) and (max-width: 1300px) {
    .herocircle:not(.is-animating) .circle:hover {
      width: 605px !important;
      height: 605px !important; } }
  @media only screen and (min-width: 991px) and (max-width: 1040px) {
    .herocircle:not(.is-animating) .circle:hover {
      width: 600px !important;
      height: 600px !important; } }
  .herocircle [class^="icon-"] {
    font-size: 38px;
    line-height: 1.15789474;
    -webkit-transform: translateY(130px);
            transform: translateY(130px); }
    @media only screen and (max-width: 1040px) {
      .herocircle [class^="icon-"] {
        -webkit-transform: translateY(80px);
                transform: translateY(80px); } }
    @media only screen and (max-width: 767px) {
      .herocircle [class^="icon-"] {
        font-size: 22px;
        line-height: 1.36363636;
        -webkit-transform: translateY(30px);
                transform: translateY(30px); } }

.circle {
  width: 735px;
  height: 735px;
  background-color: #FF626F;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 1;
  cursor: pointer; }
  @media only screen and (max-width: 1300px) {
    .circle {
      width: 580px;
      height: 580px; } }
  @media only screen and (max-width: 1040px) {
    .circle {
      width: 550px;
      height: 550px; } }
  @media only screen and (max-width: 767px) {
    .circle {
      width: 323px;
      height: 323px; } }
  @media only screen and (max-width: 350px) {
    .circle {
      width: 277px;
      height: 277px; } }

@media only screen and (max-width: 1040px) {
  .home__wrapper {
    position: relative;
    top: -110px;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: Center;
    justify-content: center; } }

@media only screen and (max-width: 767px) {
  .home__wrapper {
    margin-bottom: 100px; } }

@media only screen and (max-width: 350px) {
  .home__wrapper {
    margin-bottom: 200px; } }

.home__splash {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .home__splash .home__splash__logo {
    opacity: 0;
    width: 300px; }
    @media only screen and (max-width: 540px) {
      .home__splash .home__splash__logo {
        width: 200px; } }

.circles__new {
  position: absolute;
  pointer-events: none;
  width: 1440px;
  height: 1640px;
  left: 50%;
  -webkit-transform: translate(-50%, -10%);
          transform: translate(-50%, -10%);
  display: block; }

.circles__item {
  position: absolute;
  background-color: #E8E0F0;
  border-radius: 50%; }
  .circles__item:nth-of-type(1) {
    background-color: #F1D6BB; }
  .circles__item:nth-of-type(2) {
    background-color: #D7FFFF; }
  .circles__item:nth-of-type(3) {
    background-color: #DDDCD8; }
  .circles__item:nth-of-type(4) {
    background-color: #B8E3EC; }
  .circles__item:nth-of-type(5) {
    background-color: #E8E0F0; }
  .circles__item:nth-of-type(6) {
    background-color: #C1DDF2; }
  .circles__item:nth-of-type(7) {
    background-color: #C1F1F9; }
  .circles__item:nth-of-type(8) {
    background-color: #E5DCCC; }
  .circles__item:nth-of-type(9) {
    background-color: #C1DDF2; }
  .circles__item > div {
    width: 100%;
    height: 100%;
    position: relative; }
  .circles__item img {
    max-width: none;
    width: 100%;
    object-fit: contain; }

.circles__carousel {
  border-radius: 50%;
  overflow: hidden;
  z-index: 10;
  position: relative; }

.circles__caption {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(10deg);
          transform: translate(-50%, -50%) rotate(10deg);
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  pointer-events: none;
  overflow: visible; }
  .circles__caption path {
    fill: transparent; }
  .circles__caption text {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 1.57142857; }
  .circles__caption.circle--left {
    -webkit-transform: translate(-50%, -50%) rotate(-110deg);
            transform: translate(-50%, -50%) rotate(-110deg); }
  @media only screen and (max-width: 840px) {
    .circles__caption {
      width: calc(100% + 30px);
      height: calc(100% + 30px); }
      .circles__caption text {
        font-size: 10px;
        line-height: 2.4; } }

.home__new {
  position: relative;
  height: auto;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  @media only screen and (max-width: 1040px) {
    .home__new {
      flex-direction: column;
      height: auto;
      position: relative; } }

.circle__content__new {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  max-width: 300px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }
  @media only screen and (max-width: 840px) {
    .circle__content__new {
      max-width: 70%; } }
  @media only screen and (max-width: 767px) {
    .circle__content__new {
      max-width: 50%; } }
  @media only screen and (max-width: 400px) {
    .circle__content__new {
      max-width: 60%; } }
  .circle__content__new h1 {
    font-size: 48px;
    line-height: 0.9;
    text-align: center; }
    @media only screen and (max-width: 1040px) {
      .circle__content__new h1 {
        font-size: 48px; } }
    @media only screen and (max-width: 840px) {
      .circle__content__new h1 {
        font-size: 38px; } }
    @media only screen and (max-width: 767px) {
      .circle__content__new h1 {
        font-size: 32px; } }
    .lang-jp .circle__content__new h1 {
      font-size: 48px;
      line-height: 1; }
      @media only screen and (max-width: 1040px) {
        .lang-jp .circle__content__new h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 840px) {
        .lang-jp .circle__content__new h1 {
          font-size: 38px; } }
      @media only screen and (max-width: 767px) {
        .lang-jp .circle__content__new h1 {
          font-size: 32px; } }
  .circle__content__new h4 {
    line-height: 1.2;
    text-align: center; }
    @media only screen and (max-width: 840px) {
      .circle__content__new h4 {
        font-size: 1.1em; } }
    @media only screen and (max-width: 767px) {
      .circle__content__new h4 {
        font-size: 1em; } }
    .lang-jp .circle__content__new h4 {
      font-size: 1.2em; }
      @media only screen and (max-width: 840px) {
        .lang-jp .circle__content__new h4 {
          font-size: 1em; } }
      @media only screen and (max-width: 767px) {
        .lang-jp .circle__content__new h4 {
          font-size: 1em; } }
  .circle__content__new button {
    pointer-events: all; }

.herocircle__new {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .herocircle__new:not(.is-animating) .circle {
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out; }
    @media only screen and (min-width: 991px) {
      .herocircle__new:not(.is-animating) .circle:hover {
        width: 760px !important;
        height: 760px !important; } }
  @media only screen and (min-width: 991px) and (max-width: 1300px) {
    .herocircle__new:not(.is-animating) .circle:hover {
      width: 605px !important;
      height: 605px !important; } }
  @media only screen and (min-width: 991px) and (max-width: 1040px) {
    .herocircle__new:not(.is-animating) .circle:hover {
      width: 600px !important;
      height: 600px !important; } }
  .herocircle__new [class^="icon-"] {
    font-size: 38px;
    line-height: 1.15789474;
    -webkit-transform: translateY(60px);
            transform: translateY(60px); }
    @media only screen and (max-width: 1040px) {
      .herocircle__new [class^="icon-"] {
        -webkit-transform: translateY(50px);
                transform: translateY(50px); } }
    @media only screen and (max-width: 840px) {
      .herocircle__new [class^="icon-"] {
        -webkit-transform: translateY(44px);
                transform: translateY(44px); } }
    @media only screen and (max-width: 767px) {
      .herocircle__new [class^="icon-"] {
        font-size: 22px;
        line-height: 1.36363636;
        -webkit-transform: translateY(28px);
                transform: translateY(28px); } }
    @media only screen and (max-width: 400px) {
      .herocircle__new [class^="icon-"] {
        font-size: 22px;
        line-height: 1.36363636;
        -webkit-transform: translateY(12px);
                transform: translateY(12px); } }

.circle__new {
  width: 460px;
  height: 460px;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FF626F;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 1;
  cursor: pointer; }
  @media only screen and (max-width: 1040px) {
    .circle__new {
      width: 440px;
      height: 440px; } }
  @media only screen and (max-width: 840px) {
    .circle__new {
      width: 380px;
      height: 380px; } }
  @media only screen and (max-width: 767px) {
    .circle__new {
      width: 323px;
      height: 323px; } }
  @media only screen and (max-width: 400px) {
    .circle__new {
      width: 277px;
      height: 277px; } }

@media only screen and (max-width: 540px) {
  .home__wrapper__new {
    margin-top: 180px; } }

.home__splash {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .home__splash .home__splash__logo {
    opacity: 0;
    width: 300px; }
    @media only screen and (max-width: 540px) {
      .home__splash .home__splash__logo {
        width: 200px; } }

.home__new__approach__circle__container {
  position: relative; }

.home__new__approach__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 80px;
  height: 80px;
  z-index: 10;
  position: absolute;
  top: 0;
  -webkit-transform: translate(220px, 280px);
          transform: translate(220px, 280px); }
  @media only screen and (max-width: 1200px) {
    .home__new__approach__circle {
      -webkit-transform: translate(220px, 308px);
              transform: translate(220px, 308px); } }
  @media only screen and (max-width: 1040px) {
    .home__new__approach__circle {
      -webkit-transform: translate(200px, 240px);
              transform: translate(200px, 240px); } }
  @media only screen and (max-width: 840px) {
    .home__new__approach__circle {
      -webkit-transform: translate(180px, 220px);
              transform: translate(180px, 220px); } }
  @media only screen and (max-width: 767px) {
    .home__new__approach__circle {
      top: 65%;
      left: 65%;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); } }
  @media only screen and (max-width: 400px) {
    .home__new__approach__circle {
      width: 50px;
      height: 50px;
      top: 65%;
      left: 65%;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); } }

@media only screen and (max-width: 767px) {
  .home__new__reverseForMobile {
    display: flex;
    flex-direction: column-reverse; } }

@media only screen and (max-width: 767px) {
  .home__new__bridge {
    width: 100%; } }

.home__new__carousel__left {
  width: 480px;
  height: 480px;
  left: -160px;
  top: -120px; }
  @media only screen and (max-width: 1200px) {
    .home__new__carousel__left {
      left: -120px; } }
  @media only screen and (max-width: 1040px) {
    .home__new__carousel__left {
      width: 420px;
      height: 420px; } }
  @media only screen and (max-width: 840px) {
    .home__new__carousel__left {
      width: 360px;
      height: 360px;
      top: -80px;
      left: -100px; } }
  @media only screen and (max-width: 767px) {
    .home__new__carousel__left {
      width: 360px;
      height: 360px;
      top: 0px;
      left: 0px;
      margin: 40px auto 60px auto; } }
  @media only screen and (max-width: 400px) {
    .home__new__carousel__left {
      width: 240px;
      height: 240px;
      margin-top: 40px;
      margin-bottom: 60px; } }

.home__new__carousel__right {
  width: 480px;
  height: 480px;
  top: -40px;
  right: 0px; }
  @media only screen and (max-width: 1040px) {
    .home__new__carousel__right {
      width: 420px;
      height: 420px;
      top: 0px; } }
  @media only screen and (max-width: 840px) {
    .home__new__carousel__right {
      width: 360px;
      height: 360px; } }
  @media only screen and (max-width: 767px) {
    .home__new__carousel__right {
      width: 360px;
      height: 360px;
      top: 0px;
      left: 0px;
      margin: 40px auto 60px auto; } }
  @media only screen and (max-width: 400px) {
    .home__new__carousel__right {
      width: 240px;
      height: 240px;
      margin-top: 40px;
      margin-bottom: 60px; } }

.home__new .flickity-page-dots {
  bottom: 90px;
  text-align: center; }
  @media only screen and (max-width: 1040px) {
    .home__new .flickity-page-dots {
      bottom: 100px; } }
  @media only screen and (max-width: 400px) {
    .home__new .flickity-page-dots {
      bottom: 90px; } }

.home__new__ourblog__wrapper {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog__wrapper {
      flex-direction: column; } }

.home__new__ourblog__container {
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog__container {
      text-align: center;
      justify-content: center;
      flex-direction: column; }
      .home__new__ourblog__container.col--10 {
        margin: 0px; } }

.home__new__ourblog__mobileBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.home__new__ourblog p {
  display: block;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  border: 1px solid;
  text-align: center;
  line-height: 360px;
  font-size: 1.6em;
  padding-left: 20px; }
  @media only screen and (max-width: 1300px) {
    .home__new__ourblog p {
      width: 300px;
      height: 300px;
      line-height: 300px; } }
  @media only screen and (max-width: 1200px) {
    .home__new__ourblog p {
      width: 280px;
      height: 280px;
      line-height: 280px; } }
  @media only screen and (max-width: 1040px) {
    .home__new__ourblog p {
      width: 260px;
      height: 260px;
      line-height: 260px; } }
  @media only screen and (max-width: 840px) {
    .home__new__ourblog p {
      width: 220px;
      height: 220px;
      line-height: 220px; } }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog p {
      width: 220px;
      height: 220px;
      line-height: 40px;
      text-align: center;
      padding-left: 0px;
      padding-top: 64px; } }

.home__new__ourblog__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute;
  top: 0;
  -webkit-transform: translate(32px, 32px);
          transform: translate(32px, 32px); }
  @media only screen and (max-width: 1300px) {
    .home__new__ourblog__circle {
      -webkit-transform: translate(26px, 26px);
              transform: translate(26px, 26px); } }
  @media only screen and (max-width: 1200px) {
    .home__new__ourblog__circle {
      -webkit-transform: translate(20px, 20px);
              transform: translate(20px, 20px); } }
  @media only screen and (max-width: 1040px) {
    .home__new__ourblog__circle {
      -webkit-transform: translate(20px, 20px);
              transform: translate(20px, 20px); } }
  @media only screen and (max-width: 840px) {
    .home__new__ourblog__circle {
      -webkit-transform: translate(14px, 14px);
              transform: translate(14px, 14px); } }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog__circle {
      -webkit-transform: translate(-80px, 12px);
              transform: translate(-80px, 12px); } }

.home__new__ourblog__layout {
  position: relative;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog__layout {
      flex-direction: column; } }

.home__new__ourblog__image {
  aspect-ratio: 1/1;
  margin-left: -40px;
  border-radius: 50%;
  overflow: hidden; }
  .home__new__ourblog__image img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  @media only screen and (max-width: 1300px) {
    .home__new__ourblog__image {
      margin-left: -80px; } }
  @media only screen and (max-width: 991px) {
    .home__new__ourblog__image {
      margin-left: -60px; } }
  @media only screen and (max-width: 767px) {
    .home__new__ourblog__image {
      width: 140px;
      height: 140px;
      margin: -60px 0px 20px 0px; } }

.home__new__ctabanner__wrapper {
  background-image: linear-gradient(0deg, #FFFFFF 0%, #ffdddf 50%, #FFFFFF 100%); }
  @media only screen and (max-width: 1040px) {
    .home__new__ctabanner__wrapper {
      margin-top: 60px; } }
  @media only screen and (max-width: 840px) {
    .home__new__ctabanner__wrapper {
      margin-top: 20px; } }
  @media only screen and (max-width: 767px) {
    .home__new__ctabanner__wrapper {
      margin-top: 80px;
      text-align: center; } }
  .home__new__ctabanner__wrapper.full-bleed {
    width: calc(100% + 600px);
    margin-left: -300px;
    padding-left: 300px;
    padding-right: 300px; }

.home__new__ctabanner__container {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .home__new__ctabanner__container {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .home__new__ctabanner__container h5 {
    line-height: 1.2; }
    @media only screen and (max-width: 1040px) {
      .home__new__ctabanner__container h5 {
        font-size: 1.1em; } }
    @media only screen and (max-width: 840px) {
      .home__new__ctabanner__container h5 {
        font-size: 1em; } }
    @media only screen and (max-width: 767px) {
      .home__new__ctabanner__container h5 {
        font-size: 0.9em; } }

.home__new__ctabanner__button a {
  background: #FF626F;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 180px;
  max-width: 280px;
  padding: 10px 25px;
  color: #FFFFFF;
  transition: 0.3s ease-in-out;
  font-weight: 500; }
  @media only screen and (max-width: 767px) {
    .home__new__ctabanner__button a {
      font-family: sofia-pro, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      line-height: 1.875;
      width: 240px;
      margin-top: 20px; } }
  .home__new__ctabanner__button a:hover {
    background: #FFFFFF;
    color: #FF626F;
    border: 1px solid #FF626F; }

.layout--back {
  z-index: -1; }

@media only screen and (max-width: 767px) {
  .home__new .btn__container {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 40px; }
    .home__new .btn__container li {
      text-align: center;
      margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .home__new .btn {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1.875;
    background-color: #FF626F;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    z-index: 1000;
    width: 240px;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    position: relative;
    left: 0;
    margin: 0 auto;
    transition: left 0.2s ease-in-out, color 0.2s ease-in-out; }
    .home__new .btn:before {
      content: none; }
    .home__new .btn:hover {
      background-color: #FFFFFF;
      color: #FF626F;
      border: 1px solid #FF626F; } }

@media only screen and (max-width: 767px) {
  .home__new__borderTop__mobile {
    border-top: 2px solid #001928;
    padding-top: 28px; } }

.home__new__ourPrinciples__image {
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .home__new__ourPrinciples__image img {
      width: 80%;
      margin-top: 28px; } }
  @media only screen and (max-width: 400px) {
    .home__new__ourPrinciples__image img {
      width: 90%;
      margin-top: 28px; } }

.home__new__stats {
  background-color: #fffafa; }

.home__new__stats__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center; }

@media only screen and (max-width: 767px) {
  .home__new__stats__box {
    width: 50%; } }

.home__new__stat__number {
  font-size: 80px;
  font-weight: 300;
  color: #FF626F; }
  @media only screen and (max-width: 767px) {
    .home__new__stat__number {
      font-size: 64px; } }
  @media only screen and (max-width: 400px) {
    .home__new__stat__number {
      font-size: 56px; } }

.home__new__stat__caption {
  margin-top: -12px;
  line-height: 1em; }
  @media only screen and (max-width: 400px) {
    .home__new__stat__caption {
      font-size: 0.8em; } }

.stat__text__small {
  display: inline;
  font-size: 50%;
  font-weight: 400; }

.teamgrid {
  clear: both; }
  .teamgrid:after {
    clear: both; }
  .teamgrid:before, .teamgrid:after {
    content: '';
    display: table; }

.teamgrid {
  display: block;
  position: relative;
  width: calc(100% + 54px);
  margin-left: -27px; }
  @media only screen and (max-width: 767px) {
    .teamgrid {
      width: 100%;
      margin-left: 0; } }
  .lang-jp .teamgrid .sectiontitle {
    font-weight: 600; }
  .teamgrid > div {
    width: calc(33.3333333% - 54px);
    float: left;
    margin: 0 27px 54px;
    cursor: pointer; }
    @media only screen and (max-width: 1200px) {
      .teamgrid > div {
        width: calc(50% - 54px); } }
    @media only screen and (max-width: 767px) {
      .teamgrid > div {
        width: 100%;
        margin-right: 0;
        margin-left: 0; } }
    @media only screen and (min-width: 1040px) {
      .teamgrid > div:not(.is-expanded):hover .teamgrid__window {
        -webkit-clip-path: circle(52.5% at center) !important;
                clip-path: circle(52.5% at center) !important; }
      .teamgrid > div:not(.is-expanded):hover .teamgrid__memberinfo {
        -webkit-transform: translateX(-30px);
                transform: translateX(-30px); } }

.teamgrid__window {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-clip-path: circle(50% at center);
          clip-path: circle(50% at center);
  transition: -webkit-clip-path 0.4s ease-out;
  transition: clip-path 0.4s ease-out;
  transition: clip-path 0.4s ease-out, -webkit-clip-path 0.4s ease-out; }
  .teamgrid__window img {
    height: 130%;
    object-fit: contain;
    max-width: none; }

_:-ms-lang(x), .teamgrid__window {
  overflow: hidden; }

.teamgrid__member {
  width: 100%;
  padding-bottom: 28.62%;
  position: relative; }
  @media only screen and (max-width: 1200px) {
    .teamgrid__member {
      padding-bottom: 44.62%; } }
  @media only screen and (max-width: 767px) {
    .teamgrid__member {
      padding-bottom: 100%; } }

.teamgrid__memberinfo {
  position: absolute;
  top: 20px;
  left: 0;
  transition: -webkit-transform 0.4s ease-out 0.04s;
  transition: transform 0.4s ease-out 0.04s;
  transition: transform 0.4s ease-out 0.04s, -webkit-transform 0.4s ease-out 0.04s; }
  @media only screen and (max-width: 400px) {
    .teamgrid__memberinfo {
      top: 10px; } }

.team__teamphoto__container img {
  width: 100%; }

.dropdown__menu li.is-selected:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wil' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dropdown__menu li.is-selected:before {
  content: "\E915"; }

.dropdown {
  position: relative; }
  .dropdown.is-open {
    z-index: 1000; }
    .dropdown.is-open .dropdown__toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background: #FFFFFF; }
    .dropdown.is-open .dropdown__menu {
      transition: max-height 0.15s ease-out, opacity 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: max-height 0.15s ease-out, opacity 0.15s ease-out, transform 0.15s ease-out;
      transition: max-height 0.15s ease-out, opacity 0.15s ease-out, transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
      max-height: 320px;
      opacity: 1;
      -webkit-transform: none;
              transform: none; }
    .dropdown.is-open .dropdown__icon {
      color: #FF626F; }

.dropdown__toggle {
  border-bottom: 1px solid #FF626F;
  color: #FF626F;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.dropdown--input .dropdown__menu .dropdown__item {
  display: flex;
  align-items: center; }

.dropdown--input .dropdown__menu img {
  width: 30px;
  margin-right: 20px; }

.dropdown__icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.dropdown__phantom-menu {
  display: none; }

.dropdown__menu {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: absolute;
  background: #FFFFFF;
  left: 0;
  width: 100%;
  z-index: 1;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  border: 2px solid #000000;
  max-height: 0;
  opacity: 0;
  -webkit-transform: translateY(-47px);
          transform: translateY(-47px);
  z-index: 20; }
  @media only screen and (max-width: 991px) {
    .dropdown__menu {
      bottom: auto;
      top: 0; } }
  .dropdown__menu li {
    position: relative;
    cursor: pointer;
    padding: 5px 32px;
    width: 100%; }
    .dropdown__menu li:hover, .dropdown__menu li.is-selected {
      background-color: #FF626F; }
    .dropdown__menu li.is-selected:before {
      position: absolute;
      left: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.dropdown__item--active:before {
  position: absolute;
  right: 1.3em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.blog {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  z-index: 1; }
  @media only screen and (max-width: 991px) {
    .blog {
      flex-direction: column;
      overflow: visible;
      height: auto; } }
  .lang-jp .blog h1.typ--jp {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600; }
  .lang-jp .blog h1.typ--jp.typ--h3 {
    font-size: 28px;
    line-height: 1.5; }

.insights__circle {
  width: 135px;
  height: 135px;
  background-color: #FF626F;
  border-radius: 50%;
  flex-shrink: 0; }
  @media only screen and (max-width: 1200px) {
    .insights__circle {
      width: 8rem;
      height: 8rem; } }

.insights__filters {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 120px;
  flex: 1 1;
  z-index: 2; }
  @media only screen and (max-width: 1200px) {
    .insights__filters {
      padding-bottom: 6rem; } }
  @media only screen and (max-width: 991px) {
    .insights__filters {
      flex: auto; } }
  @media only screen and (max-width: 400px) {
    .insights__filters {
      padding-bottom: 60px; } }
  .insights__filters .tabs {
    flex-wrap: wrap; }
    .insights__filters .tabs li {
      white-space: nowrap;
      margin-bottom: 20px; }

.insight__title__wrapper {
  width: 100%;
  z-index: 10; }

.insights__window {
  height: 100%;
  width: 100%;
  overflow: auto;
  flex: 1 1;
  z-index: 1; }
  @media only screen and (max-width: 991px) {
    .insights__window {
      flex: auto;
      overflow: visible; } }

.blog__item {
  border-top: 2px solid #001928;
  padding-top: 25px;
  padding-bottom: 55px;
  width: calc(50% - 28px); }
  @media only screen and (max-width: 767px) {
    .blog__item {
      width: 100%; } }
  .blog__item a {
    color: #001928;
    cursor: pointer; }

.blog__item__img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  float: left; }
  .blog__item__img img {
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover; }
  @media only screen and (max-width: 1200px) {
    .blog__item__img {
      width: 100px;
      height: 100px; } }
  @media only screen and (max-width: 991px) {
    .blog__item__img {
      float: none;
      width: 120px;
      height: 120px;
      margin-bottom: 16px; } }

.blog__item__date {
  margin-bottom: 0;
  font-size: 0.8em; }

.blog__author__link:hover {
  color: #FF626F; }

.blog__layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .blog__layout li {
    display: block; }

.pagination {
  display: flex;
  justify-content: center; }
  .pagination ul {
    display: flex; }
  .pagination .page-item {
    margin-left: 10px;
    margin-right: 10px; }
    .pagination .page-item.active {
      font-weight: 600; }

.blog__followUsOn__wrapper {
  position: relative; }

.blog__social ul {
  display: flex; }

.blog__social li {
  font-size: 28px;
  margin-right: 20px; }

.blog__disclaimer {
  width: 80%;
  margin: 0 auto;
  line-height: 0.8em; }
  @media only screen and (max-width: 1040px) {
    .blog__disclaimer {
      width: 90%; } }
  .blog__disclaimer a {
    font-size: 0.8em;
    color: #9ca6ab; }
    .blog__disclaimer a:hover {
      color: #001928; }

.icon-x-tentative:before {
  content: "";
  background-color: #ff626f;
  display: inline-block;
  height: 1.6rem;
  width: 1.6rem;
  -webkit-mask: url(/static/media/x.2dc07983.svg);
  mask: url(/static/media/x.2dc07983.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle; }

.article {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background-color: #FFFFFF; }

.article__head {
  background-color: #FBFBFB;
  margin-bottom: 120px;
  padding-top: 60px;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .article__head {
      margin-bottom: 40px; } }

.article__meta {
  padding-top: 10px;
  margin-top: 60px;
  border-top: 2px solid #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

@media only screen and (max-width: 991px) {
  .article__flex {
    display: flex;
    flex-direction: column; } }

.article__author__link {
  color: #001928; }
  .article__author__link:hover {
    color: #FF626F; }

@media only screen and (max-width: 991px) {
  .article__title {
    justify-self: flex-end; } }

.lang-jp .article__title h1 {
  font-size: 32px;
  line-height: 1.4; }

@media only screen and (max-width: 991px) {
  .article__image {
    justify-self: flex-start;
    align-self: center;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative; } }

.article__image img {
  position: absolute;
  right: 0;
  bottom: -40px;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  object-fit: cover; }
  @media only screen and (max-width: 1200px) {
    .article__image img {
      width: 300px;
      height: 300px; } }
  @media only screen and (max-width: 991px) {
    .article__image img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }

.article__social {
  z-index: 10;
  position: relative; }
  .article__social ul {
    display: inline-flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 5px; }
    @media only screen and (max-width: 991px) {
      .article__social ul {
        flex-direction: row;
        padding: 0;
        margin: 0;
        display: flex;
        background-color: transparent; } }
  .article__social li {
    display: inline-flex; }
    @media only screen and (max-width: 991px) {
      .article__social li {
        margin: 0 25px 30px 0; } }
  .article__social a {
    color: #000000; }
    .article__social a:hover {
      color: #FF626F; }

.markdown .markdown__img-container img {
  position: absolute;
  left: -600px;
  top: -100px;
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 50%; }
  @media only screen and (max-width: 1200px) {
    .markdown .markdown__img-container img {
      left: -500px; } }
  @media only screen and (max-width: 991px) {
    .markdown .markdown__img-container img {
      position: relative;
      left: -20px;
      top: 0;
      width: 100vw;
      height: 100vw;
      max-width: 400px;
      max-height: 400px;
      margin: 20px 0; } }
  @media only screen and (max-width: 767px) {
    .markdown .markdown__img-container img {
      left: -50px; } }

.markdown .markdown__blockquote-container blockquote {
  font-size: 38px;
  line-height: 1.15789474;
  position: relative;
  left: -80px;
  margin: 50px 0;
  color: #FF626F; }
  @media only screen and (max-width: 991px) {
    .markdown .markdown__blockquote-container blockquote {
      font-size: 30px;
      line-height: 1.27777778;
      left: 0; } }
  @media only screen and (max-width: 400px) {
    .markdown .markdown__blockquote-container blockquote {
      font-size: 22px;
      line-height: 1.36363636; } }

.markdown figure.markdown__img-container-inner {
  padding: 20px 0 20px 0; }
  .markdown figure.markdown__img-container-inner figcaption {
    font-size: .7em;
    opacity: 0.7; }

.clientgrid {
  margin-bottom: 160px; }
  @media only screen and (max-width: 1040px) {
    .clientgrid {
      margin-bottom: 90px; } }
  @media only screen and (max-width: 540px) {
    .clientgrid {
      margin-bottom: 50px; } }

.clientgrid__layout {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 840px) {
    .clientgrid__layout {
      width: calc(100% + 40px);
      margin-left: -20px; } }
  .clientgrid__layout > div {
    margin: 0 30px;
    width: calc(25% - 60px);
    height: 140px;
    position: relative;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent; }
    @media only screen and (max-width: 1200px) {
      .clientgrid__layout > div {
        margin: 0 20px;
        width: calc(25% - 40px); } }
    @media only screen and (max-width: 1040px) {
      .clientgrid__layout > div {
        width: calc(33.3333333% - 60px);
        margin-left: 30px;
        margin-right: 30px; } }
    @media only screen and (max-width: 540px) {
      .clientgrid__layout > div {
        width: calc(50% - 60px);
        margin-left: 30px;
        margin-right: 30px;
        height: 120px; } }
  .clientgrid__layout img {
    width: 100%; }

*::-ms-backdrop,
.clientgrid__layout > div {
  display: inline-block; }

/* IE11 */
.clientgrid__description {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  max-height: none;
  background-color: #FF626F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  padding-bottom: calc(100% + 40px);
  width: calc(100% + 40px); }
  .clientgrid__description > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .clientgrid__description .clientgrid__descriptiontext {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 30);
            transform: matrix(1, 0, 0, 1, 0, 30);
    line-height: 1.4;
    padding: 10px 20px 10px;
    text-align: center; }

.clientgrid__exitinfo {
  font-size: 10px;
  line-height: 2.4;
  line-height: 1.5;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 0.5); }

.clientgrid {
  margin-bottom: 100px; }
  @media only screen and (max-width: 1040px) {
    .clientgrid {
      margin-bottom: 90px; } }
  @media only screen and (max-width: 540px) {
    .clientgrid {
      margin-bottom: 50px; } }

.clientgrid__layout {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 840px) {
    .clientgrid__layout {
      width: calc(100% + 40px);
      margin-left: -20px; } }
  .clientgrid__layout > div {
    margin: 0 30px;
    width: calc(25% - 60px);
    height: 140px;
    position: relative;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent; }
    @media only screen and (max-width: 1200px) {
      .clientgrid__layout > div {
        margin: 0 20px;
        width: calc(25% - 40px); } }
    @media only screen and (max-width: 1040px) {
      .clientgrid__layout > div {
        width: calc(33.3333333% - 60px);
        margin-left: 30px;
        margin-right: 30px; } }
    @media only screen and (max-width: 540px) {
      .clientgrid__layout > div {
        width: calc(50% - 60px);
        margin-left: 30px;
        margin-right: 30px;
        height: 120px; } }
  .clientgrid__layout img {
    width: 100%; }

*::-ms-backdrop,
.clientgrid__layout > div {
  display: inline-block; }

/* IE11 */
.clientgrid__description {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  max-height: none;
  background-color: #FF626F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  padding-bottom: calc(100% + 40px);
  width: calc(100% + 40px); }
  .clientgrid__description > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .clientgrid__description .clientgrid__descriptiontext {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 30);
            transform: matrix(1, 0, 0, 1, 0, 30);
    line-height: 1.4;
    padding: 10px 20px 10px;
    text-align: center; }

.clientgrid__exitinfo {
  font-size: 10px;
  line-height: 2.4;
  line-height: 1.5;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 0.5); }

.companiescarousel .flickity-viewport {
  overflow: visible; }

.companiescarousel .carousel__slide {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none; }
  .companiescarousel .carousel__slide.is-selected {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.4s ease-in-out; }
    .companiescarousel .carousel__slide.is-selected .companiescarousel__slidetag {
      -webkit-transform: none;
              transform: none; }
    .companiescarousel .carousel__slide.is-selected .companiescarousel__slidelogo {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: auto; }

.companiescarousel__slidetag {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  position: absolute;
  right: 8%;
  top: 10%;
  z-index: 10;
  transition: color 0.4s ease-in-out, -webkit-transform 0.6s ease-in-out 0.1s;
  transition: transform 0.6s ease-in-out 0.1s, color 0.4s ease-in-out;
  transition: transform 0.6s ease-in-out 0.1s, color 0.4s ease-in-out, -webkit-transform 0.6s ease-in-out 0.1s; }

.companiescarousel__tagline {
  position: absolute;
  z-index: 10; }
  @media only screen and (max-width: 1040px) {
    .companiescarousel__tagline {
      position: relative; } }
  .lang-jp .companiescarousel__tagline h1.typ--jp {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600; }
  .lang-jp .companiescarousel__tagline h1.typ--jp.typ--h2 {
    font-size: 28px;
    line-height: 1.5; }

.companiescarousel__image {
  position: relative;
  padding-bottom: 48.386%;
  background-color: #E8E0F0;
  border-radius: 50%;
  overflow: hidden; }
  @media only screen and (max-width: 1040px) {
    .companiescarousel__image {
      padding-bottom: 73.784%; } }
  @media only screen and (max-width: 767px) {
    .companiescarousel__image {
      padding-bottom: 100%; } }
  @media only screen and (min-width: 1040px) {
    .companiescarousel__image:hover .companiescarousel__quote {
      opacity: 1;
      pointer-events: auto; }
      .companiescarousel__image:hover .companiescarousel__quote > div {
        -webkit-transform: none;
                transform: none;
        opacity: 1; }
    .companiescarousel__image:hover > span {
      color: #FF626F; } }

.companiescarousel__slideimg {
  display: block;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.companiescarousel__slidelogo {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(calc(-50% + 20px), -50%);
          transform: translate(calc(-50% + 20px), -50%);
  opacity: 0;
  transition: opacity 0.7s ease-in-out 0.2s, -webkit-transform 0.7s ease-in-out 0.2s;
  transition: transform 0.7s ease-in-out 0.2s, opacity 0.7s ease-in-out 0.2s;
  transition: transform 0.7s ease-in-out 0.2s, opacity 0.7s ease-in-out 0.2s, -webkit-transform 0.7s ease-in-out 0.2s; }

.companiescarousel__quote {
  position: absolute;
  background-color: #FFFFFF;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 125px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out; }
  @media only screen and (max-width: 1400px) {
    .companiescarousel__quote {
      padding-left: 60px;
      padding-right: 60px; } }
  @media only screen and (max-width: 1040px) {
    .companiescarousel__quote {
      padding-left: 40px;
      padding-right: 40px; } }
  .companiescarousel__quote > div {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.2s, -webkit-transform 0.3s ease-in-out 0.2s;
    transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s;
    transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s, -webkit-transform 0.3s ease-in-out 0.2s; }
  .lang-jp .companiescarousel__quote h5 {
    font-size: 1.1em;
    line-height: 1.6em; }

.companies__scroller {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px; }
  @media only screen and (max-width: 991px) {
    .companies__scroller {
      padding-left: 60px;
      padding-right: 60px; } }
  @media only screen and (max-width: 767px) {
    .companies__scroller {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 400px) {
    .companies__scroller {
      padding-left: 25px;
      padding-right: 25px; } }

.companies__scroller {
  width: calc(100% + 200px);
  margin-left: -100px; }
  @media only screen and (max-width: 991px) {
    .companies__scroller {
      width: calc(100% + 120px);
      margin-left: -60px; } }
  @media only screen and (max-width: 767px) {
    .companies__scroller {
      width: calc(100% + 80px);
      margin-left: -40px; } }
  @media only screen and (max-width: 400px) {
    .companies__scroller {
      width: calc(100% + 50px);
      margin-left: -25px; } }

.companies {
  background-color: #FFFFFF; }

.companies__list {
  display: flex;
  flex-wrap: wrap; }
  .companies__list > div {
    width: 50%;
    padding-right: 50px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 1200px) {
      .companies__list > div {
        width: 100%; } }
    @media only screen and (max-width: 540px) {
      .companies__list > div {
        width: 280px; } }

.companies__scroller {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.partnergrid, .venture-funds--text {
  width: 100%;
  padding-left: 50px; }
  @media only screen and (max-width: 1040px) {
    .partnergrid, .venture-funds--text {
      padding-left: 0; } }

.lang-jp .venture-funds h2.typ--jp {
  font-size: 24px;
  line-height: 1.4; }

.venture-funds--text {
  padding-right: 60px; }

.partnergrid__container {
  display: flex;
  flex-wrap: wrap; }

.partnergrid__item {
  width: 50%;
  padding-right: 60px; }
  @media only screen and (max-width: 1040px) {
    .partnergrid__item {
      padding-right: 40px; } }
  @media only screen and (max-width: 767px) {
    .partnergrid__item {
      width: 100%;
      padding-right: 0; } }
  .partnergrid__item a {
    color: #001928; }
  .partnergrid__item:not(.has-link) a {
    cursor: default; }
  .partnergrid__item.has-link:hover p.typ--bold {
    color: #FF626F; }
  .lang-jp .partnergrid__item .typ--light {
    display: none; }

.partnergrid__section {
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .partnergrid__section {
      margin-bottom: 20px; } }

.partnergrid__section__header {
  border-top: 1px solid #001928;
  padding-top: 20px;
  display: flex;
  align-items: center; }
  .partnergrid__section__header .partnergrid__industries__icon {
    width: 48px; }
  .partnergrid__section__header img {
    margin-right: 16px; }

.lang-jp .clientgrid h2.typ--jp {
  font-size: 24px;
  line-height: 1.4; }

.companies__anchorLink {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  color: #FF626F;
  text-align: left; }
  .companies__anchorLink:hover {
    color: #001928; }

.companies__anchor__navigation {
  margin-bottom: 36px; }

.partnergrid__item img {
  max-width: 70%; }

.companies__disclaimer {
  width: 80%;
  margin: 0 auto;
  line-height: 1em;
  font-size: 0.8em;
  color: #9ca6ab; }
  @media only screen and (max-width: 1040px) {
    .companies__disclaimer {
      width: 90%; } }
  .companies__disclaimer a {
    font-size: 0.8em;
    color: #9ca6ab; }
    .companies__disclaimer a:hover {
      color: #001928; }

.member {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #FFFFFF;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

.member__bio {
  padding-top: 40vh;
  height: 100vh;
  position: relative; }
  @media only screen and (max-width: 1040px) {
    .member__bio {
      padding-top: 28vh; } }
  @media only screen and (max-width: 840px) {
    .member__bio {
      padding-top: 430px; } }

.member__name {
  max-width: 500px;
  margin-bottom: 90px;
  z-index: 120;
  position: relative; }
  @media only screen and (max-width: 840px) {
    .member__name {
      margin-left: 0;
      margin-bottom: 50px; } }

.member__content {
  font-size: 20px;
  line-height: 1.9;
  font-family: sofia-pro, sans-serif;
  font-weight: 300;
  font-style: normal; }
  @media only screen and (max-width: 840px) {
    .member__content {
      font-size: 16px;
      line-height: 1.875; } }
  .lang-jp .member__content {
    font-size: 18px;
    line-height: 1.8; }
  .member__content p {
    margin-bottom: 50px; }
    @media only screen and (max-width: 840px) {
      .member__content p {
        margin-bottom: 30px; } }

.member__nameline {
  height: 2px;
  width: 100%;
  background-color: #001928;
  margin-bottom: 20px;
  display: block; }

.member__img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  position: relative;
  pointer-events: none; }
  @media only screen and (max-width: 840px) {
    .member__img {
      height: auto;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      top: -665px;
      width: 1045px;
      height: 1045px; } }

.member__imgwindow {
  position: fixed;
  top: 50%;
  width: 4000px;
  height: 4000px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
  -webkit-clip-path: circle(50%); }
  @media only screen and (max-width: 840px) {
    .member__imgwindow {
      position: absolute;
      height: 100%;
      width: 100%;
      top: auto;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); } }
  .member__imgwindow img {
    width: 95vw;
    position: absolute;
    right: -15vw;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media only screen and (max-width: 1200px) {
      .member__imgwindow img {
        right: -25vw; } }
    @media only screen and (max-width: 840px) {
      .member__imgwindow img {
        width: 540px;
        right: auto;
        bottom: -50px;
        top: auto;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 50%; } }

*::-ms-backdrop,
.member__imgwindow {
  width: 45vw;
  height: 100vh;
  transform: none;
  top: 0;
  left: 0;
  right: auto;
  border-radius: 0; }
  @media only screen and (max-width: 1200px) {
    *::-ms-backdrop,
    .member__imgwindow {
      width: 32vw; } }

/* IE11 */
*::-ms-backdrop,
.member__imgwindow img {
  width: 800px;
  max-width: none;
  left: 50%;
  right: auto;
  top: auto;
  bottom: 0;
  transform: translateX(-50%); }
  @media only screen and (max-width: 1200px) {
    *::-ms-backdrop,
    .member__imgwindow img {
      width: 600px; } }

*::-ms-backdrop,
.member__name {
  margin-left: 0; }

.member__social {
  display: flex; }
  .member__social li a {
    font-size: 20px;
    line-height: 1.9;
    margin-right: 30px;
    color: #001928; }
    .member__social li a:hover {
      color: #FF626F; }

.member__fact {
  position: relative; }
  @media only screen and (max-width: 840px) {
    .member__fact {
      padding-left: 60px; } }
  .member__fact [class^="icon-"] {
    color: #FF626F;
    font-size: 40px; }
    @media only screen and (max-width: 840px) {
      .member__fact [class^="icon-"] {
        position: absolute;
        left: 0; } }

.member__next {
  background-color: #FBFBFB;
  position: relative;
  z-index: 5; }
  .member__next a {
    height: 144px;
    color: #001928;
    display: flex;
    align-items: center;
    width: 100%; }
  .member__next img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px; }
  .member__next:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 144px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #FBFBFB;
    z-index: -1; }

.member__companies span {
  display: inline-block; }

.company-link {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  transition: color 0.2s ease;
  cursor: pointer; }
  .company-link:hover {
    color: #FF626F; }

.member__relatedinsights ul {
  margin-top: 2rem; }

.member__relatedinsights .insight__item {
  border-top: none;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .member__relatedinsights .insight__item button {
    font-family: inherit;
    color: #001928;
    font-weight: 300; }
  .member__relatedinsights .insight__item a {
    color: #001928;
    font-weight: 300; }
  .member__relatedinsights .insight__item .insight__title {
    line-height: 1.5em;
    margin-bottom: 0em;
    text-align: left;
    transition: 0.2s; }
    .member__relatedinsights .insight__item .insight__title:hover {
      color: #FF626F; }

.mobilenav {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: white;
  background-position: center bottom;
  background-size: cover;
  z-index: 2000; }
  .mobilenav li {
    font-size: 30px;
    line-height: 1.27777778;
    padding: 10px 0 10px;
    text-align: center;
    display: block; }
    @media only screen and (max-width: 1040px) {
      .mobilenav li {
        font-size: 22px;
        line-height: 1.36363636;
        padding: 8px 0 8px; } }
    .mobilenav li a {
      color: #FFFFFF; }
    .mobilenav li.is-active:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 5px;
      height: 5px;
      background-color: #FF626F;
      border-radius: 50%; }

.mobilenav__header {
  z-index: 3;
  color: #FFFFFF; }
  .mobilenav__header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px; }

.mobilenav__top {
  height: 70vh;
  min-height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; }
  .mobilenav__top:after {
    content: '';
    position: absolute;
    background-image: url(/static/media/mobilenav_mask.849e1205.svg);
    width: 1657px;
    height: 1657px;
    bottom: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1; }
  .mobilenav__top ul {
    margin-top: -25vh;
    position: relative;
    z-index: 5; }

.mobilenav__footer {
  position: absolute;
  left: 50%;
  bottom: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2; }

.quote {
  position: fixed;
  display: flex;
  top: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  z-index: 100;
  background-color: #FFFFFF; }
  .quote .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.quote__tag {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 1.57142857;
  display: block;
  color: #FF626F;
  text-align: right;
  position: absolute;
  top: -70px;
  right: 25px; }

.quote__bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .quote__bg img {
    border-radius: 50%;
    max-width: none;
    width: 150vw;
    height: 150vw; }
  .quote__bg:after {
    content: '';
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background: #FFFFFF;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%; }

.privacypolicy {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media only screen and (max-width: 1040px) {
    .privacypolicy {
      padding-top: 40px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .privacypolicy {
      padding-top: 20px;
      padding-bottom: 40px; } }

.policypost {
  padding: 0 64px 64px 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .policypost {
      padding: 0px; } }
  .policypost .markdown {
    font-size: 0.8em; }
    .policypost .markdown ul {
      list-style: disc;
      padding-left: 3em;
      margin-bottom: 0.8em; }
      @media only screen and (max-width: 767px) {
        .policypost .markdown ul {
          padding-left: 1em; } }
    .policypost .markdown table {
      margin: 2em 0; }
      .policypost .markdown table tr {
        border-top: 1px solid #000; }
        .policypost .markdown table tr:last-child {
          border-bottom: 1px solid #000; }
        .policypost .markdown table tr td:first-child {
          width: 30%; }
      .policypost .markdown table th, .policypost .markdown table td {
        padding: 0.5em 1em; }

@media only screen and (max-width: 1040px) {
  .privacypolicy__header {
    padding-top: 0; } }

.privacypolicy__header img {
  width: calc(100% + 150px);
  max-width: none;
  position: absolute;
  right: 0;
  top: 120px; }
  @media only screen and (max-width: 1040px) {
    .privacypolicy__header img {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block; } }

.privacypolicy__header h1.typ--jp {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 400; }

.privacypolicy {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media only screen and (max-width: 1040px) {
    .privacypolicy {
      padding-top: 40px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .privacypolicy {
      padding-top: 20px;
      padding-bottom: 40px; } }

.policypost {
  padding: 0 64px 64px 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .policypost {
      padding: 0px; } }
  .policypost .markdown {
    font-size: 0.8em; }
    .policypost .markdown ul {
      list-style: disc;
      padding-left: 3em;
      margin-bottom: 0.8em; }
      @media only screen and (max-width: 767px) {
        .policypost .markdown ul {
          padding-left: 1em; } }
    .policypost .markdown table {
      margin: 2em 0; }
      .policypost .markdown table tr {
        border-top: 1px solid #000; }
        .policypost .markdown table tr:last-child {
          border-bottom: 1px solid #000; }
        .policypost .markdown table tr td:first-child {
          width: 30%; }
      .policypost .markdown table th, .policypost .markdown table td {
        padding: 0.5em 1em; }

@media only screen and (max-width: 1040px) {
  .privacypolicy__header {
    padding-top: 0; } }

.privacypolicy__header img {
  width: calc(100% + 150px);
  max-width: none;
  position: absolute;
  right: 0;
  top: 120px; }
  @media only screen and (max-width: 1040px) {
    .privacypolicy__header img {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block; } }

.privacypolicy__header h1.typ--jp {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 400; }

.approach__bullet__list li::before {
  content: "- "; }

.approach__lottie__box {
  width: 100%;
  position: relative;
  top: 0px;
  margin-bottom: 0px;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .approach__lottie__box {
      max-height: 800px;
      margin-bottom: -40px; } }
  @media only screen and (max-width: 540px) {
    .approach__lottie__box {
      max-height: 500px;
      margin-bottom: -20px; } }
  @media only screen and (max-width: 400px) {
    .approach__lottie__box {
      max-height: 400px;
      margin-bottom: -10px; } }
  @media only screen and (max-width: 350px) {
    .approach__lottie__box {
      max-height: 320px;
      margin-bottom: 0px; } }

.abcd {
  -webkit-transform: translate(0%, -25%);
          transform: translate(0%, -25%); }

.approach__lottie__adjust {
  width: 200%;
  margin-bottom: -200px;
  -webkit-transform: translate(-25%, 0%);
          transform: translate(-25%, 0%); }
  @media only screen and (max-width: 1040px) {
    .approach__lottie__adjust {
      margin-bottom: -80px; } }
  @media only screen and (max-width: 840px) {
    .approach__lottie__adjust {
      margin-bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    .approach__lottie__adjust {
      margin-bottom: -640px; } }
  @media only screen and (max-width: 640px) {
    .approach__lottie__adjust {
      margin-bottom: -520px; } }
  @media only screen and (max-width: 540px) {
    .approach__lottie__adjust {
      margin-bottom: -460px; } }
  @media only screen and (max-width: 400px) {
    .approach__lottie__adjust {
      margin-bottom: -360px; } }
  @media only screen and (max-width: 350px) {
    .approach__lottie__adjust {
      margin-bottom: -180px; } }

.approach__japan__base {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .approach__japan__base {
      margin-top: 40px;
      margin-bottom: 440px; } }
  @media only screen and (max-width: 540px) {
    .approach__japan__base {
      margin-bottom: 200px; } }
  @media only screen and (max-width: 400px) {
    .approach__japan__base {
      margin-bottom: 120px; } }

.approach__japan__image {
  max-width: 1000px;
  width: 300%;
  position: absolute;
  top: 0%;
  left: -100%;
  -webkit-transform: translate(0px, -40%);
          transform: translate(0px, -40%);
  z-index: -1;
  opacity: 0.5; }
  @media only screen and (max-width: 767px) {
    .approach__japan__image {
      max-width: none;
      width: 200%;
      top: 0;
      left: 0;
      -webkit-transform: translate(-25%, -25%);
              transform: translate(-25%, -25%); } }

.approach__japan__circle {
  border-radius: 50%;
  background-color: #FF626F;
  max-width: 320px;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }
  .approach__japan__circle:before {
    content: '';
    display: block;
    padding-top: 100%; }
  @media only screen and (max-width: 767px) {
    .approach__japan__circle {
      max-width: none;
      width: 80%;
      left: 10%;
      -webkit-transform: translate(0px, 10%);
              transform: translate(0px, 10%); } }

.approach__adjust__marginBottom {
  margin-bottom: 360px; }
  @media only screen and (max-width: 350px) {
    .approach__adjust__marginBottom {
      margin-bottom: 300px; } }

@media only screen and (max-width: 767px) {
  .approach__section__container .btn__container {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 40px; }
    .approach__section__container .btn__container li {
      text-align: center;
      margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .approach__section__container .btn {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1.875;
    background-color: #FF626F;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    z-index: 1000;
    width: 240px;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    position: relative;
    left: 0;
    margin: 0 auto;
    transition: left 0.2s ease-in-out, color 0.2s ease-in-out; }
    .approach__section__container .btn:before {
      content: none; }
    .approach__section__container .btn:hover {
      background-color: #FFFFFF;
      color: #FF626F;
      border: 1px solid #FF626F; } }

.approach__cta__text__right {
  text-align: right; }

.approach__section__text .markdown {
  font-size: 16px; }

.approach__bullet__list li::before {
  content: "- "; }

.approach__lottie__box {
  width: 100%;
  position: relative;
  top: 0px;
  margin-bottom: 0px;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .approach__lottie__box {
      max-height: 800px;
      margin-bottom: -40px; } }
  @media only screen and (max-width: 540px) {
    .approach__lottie__box {
      max-height: 500px;
      margin-bottom: -20px; } }
  @media only screen and (max-width: 400px) {
    .approach__lottie__box {
      max-height: 400px;
      margin-bottom: -10px; } }
  @media only screen and (max-width: 350px) {
    .approach__lottie__box {
      max-height: 320px;
      margin-bottom: 0px; } }

.abcd {
  -webkit-transform: translate(0%, -25%);
          transform: translate(0%, -25%); }

.approach__lottie__adjust {
  width: 200%;
  margin-bottom: -200px;
  -webkit-transform: translate(-25%, 0%);
          transform: translate(-25%, 0%); }
  @media only screen and (max-width: 1040px) {
    .approach__lottie__adjust {
      margin-bottom: -80px; } }
  @media only screen and (max-width: 840px) {
    .approach__lottie__adjust {
      margin-bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    .approach__lottie__adjust {
      margin-bottom: -640px; } }
  @media only screen and (max-width: 640px) {
    .approach__lottie__adjust {
      margin-bottom: -520px; } }
  @media only screen and (max-width: 540px) {
    .approach__lottie__adjust {
      margin-bottom: -460px; } }
  @media only screen and (max-width: 400px) {
    .approach__lottie__adjust {
      margin-bottom: -360px; } }
  @media only screen and (max-width: 350px) {
    .approach__lottie__adjust {
      margin-bottom: -180px; } }

.approach__japan__base {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .approach__japan__base {
      margin-top: 40px;
      margin-bottom: 440px; } }
  @media only screen and (max-width: 540px) {
    .approach__japan__base {
      margin-bottom: 200px; } }
  @media only screen and (max-width: 400px) {
    .approach__japan__base {
      margin-bottom: 120px; } }

.approach__japan__image {
  max-width: 1000px;
  width: 300%;
  position: absolute;
  top: 0%;
  left: -100%;
  -webkit-transform: translate(0px, -40%);
          transform: translate(0px, -40%);
  z-index: -1;
  opacity: 0.5; }
  @media only screen and (max-width: 767px) {
    .approach__japan__image {
      max-width: none;
      width: 200%;
      top: 0;
      left: 0;
      -webkit-transform: translate(-25%, -25%);
              transform: translate(-25%, -25%); } }

.approach__japan__circle {
  border-radius: 50%;
  background-color: #FF626F;
  max-width: 320px;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }
  .approach__japan__circle:before {
    content: '';
    display: block;
    padding-top: 100%; }
  @media only screen and (max-width: 767px) {
    .approach__japan__circle {
      max-width: none;
      width: 80%;
      left: 10%;
      -webkit-transform: translate(0px, 10%);
              transform: translate(0px, 10%); } }

.approach__adjust__marginBottom {
  margin-bottom: 360px; }
  @media only screen and (max-width: 350px) {
    .approach__adjust__marginBottom {
      margin-bottom: 300px; } }

@media only screen and (max-width: 767px) {
  .approach__section__container .btn__container {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 40px; }
    .approach__section__container .btn__container li {
      text-align: center;
      margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .approach__section__container .btn {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1.875;
    background-color: #FF626F;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    z-index: 1000;
    width: 240px;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    position: relative;
    left: 0;
    margin: 0 auto;
    transition: left 0.2s ease-in-out, color 0.2s ease-in-out; }
    .approach__section__container .btn:before {
      content: none; }
    .approach__section__container .btn:hover {
      background-color: #FFFFFF;
      color: #FF626F;
      border: 1px solid #FF626F; } }

.approach__cta__text__right {
  text-align: right; }

.approach__section__text .markdown {
  font-size: 16px; }

.principles__section__wrapper {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .principles__section__wrapper {
      display: block; } }

.principles__texts__wrapper {
  position: relative; }

.principles__circle {
  border-radius: 50%;
  background-color: #FF626F;
  width: 120px;
  height: 120px;
  z-index: -1;
  position: absolute;
  top: 0%;
  -webkit-transform: translate(-50%, -32%);
          transform: translate(-50%, -32%); }
  @media only screen and (max-width: 1040px) {
    .principles__circle {
      position: absolute;
      width: 100px;
      height: 100px; } }
  @media only screen and (max-width: 991px) {
    .principles__circle {
      width: 80px;
      height: 80px; } }

.principles__image {
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .principles__image {
      width: 70%; } }
  @media only screen and (max-width: 540px) {
    .principles__image {
      width: 80%; } }
  @media only screen and (max-width: 400px) {
    .principles__image {
      width: 90%; } }

.principles__image__box {
  width: 100%;
  text-align: center; }

.locationsPage__image {
  width: 100%; }

.locationsPage__carousel {
  aspect-ratio: 1/1;
  width: 80%;
  margin: 40px auto 40px auto; }
  @media only screen and (max-width: 400px) {
    .locationsPage__carousel {
      margin-top: 20px;
      margin-bottom: 20px; } }

.locationsPage__circle__US {
  width: 40px;
  height: 40px;
  background-color: #FF626F;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  top: 47%; }
  @media only screen and (max-width: 1040px) {
    .locationsPage__circle__US {
      width: 32px;
      height: 32px; } }
  @media only screen and (max-width: 767px) {
    .locationsPage__circle__US {
      width: 24px;
      height: 24px; } }

.locationsPage__circle__JP {
  width: 40px;
  height: 40px;
  background-color: #FF626F;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  top: 48%; }
  @media only screen and (max-width: 1040px) {
    .locationsPage__circle__JP {
      width: 32px;
      height: 32px; } }
  @media only screen and (max-width: 767px) {
    .locationsPage__circle__JP {
      width: 24px;
      height: 24px; } }

@media only screen and (max-width: 767px) {
  .locationsPage .btn__container {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 40px; }
    .locationsPage .btn__container li {
      text-align: center;
      margin: 0px auto 20px auto; } }

@media only screen and (max-width: 767px) {
  .locationsPage .btn {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1.875;
    background-color: #FF626F;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    z-index: 1000;
    width: 240px;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    position: relative;
    left: 0;
    margin: 0 auto;
    transition: left 0.2s ease-in-out, color 0.2s ease-in-out; }
    .locationsPage .btn:before {
      content: none; }
    .locationsPage .btn:hover {
      background-color: #FFFFFF;
      color: #FF626F;
      border: 1px solid #FF626F; } }

.contact {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media only screen and (max-width: 1040px) {
    .contact {
      padding-top: 40px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .contact {
      padding-top: 20px;
      padding-bottom: 40px; } }

.contact__form {
  padding: 0 64px 64px 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .contact__form {
      padding: 0px; } }

@media only screen and (max-width: 1040px) {
  .contact__header {
    padding-top: 0; } }

.contact__header img {
  width: calc(100% + 150px);
  max-width: none;
  position: absolute;
  right: 0;
  top: 120px; }
  @media only screen and (max-width: 1040px) {
    .contact__header img {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block; } }

.thanksMessage {
  padding: 0 64px 64px 64px;
  opacity: 0; }

.contact__form p {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px; }
  .contact__form p.no-bg {
    background-color: transparent; }

.contact__form .contact__label {
  width: 20%;
  font-size: 0.8em;
  color: #8c8c8c; }
  .contact__form .contact__label span {
    font-size: 0.5em;
    color: #FF626F; }

.contact__form .contact__input {
  width: 80%;
  background-color: #f0f0f0; }

.contact__form .contact__textarea {
  width: 80%;
  height: 160px;
  background-color: #f0f0f0;
  font-size: 0.9em; }

.contact__form .contact__attach {
  cursor: pointer; }

.contact__form select {
  cursor: pointer;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1em; }

.contact__form button {
  margin-left: -10px;
  padding: 10px 30px 10px 30px;
  background-color: #FF626F;
  border: 1px solid #FF626F;
  border-radius: 5px;
  cursor: pointer;
  color: #FFFFFF; }
  .contact__form button:hover {
    background-color: transparent;
    color: #FF626F;
    transition: 0.2s; }

.contact__form .contact__caption {
  background: none;
  padding: 0; }

/* Chrome */
::-webkit-input-placeholder {
  color: #8c8c8c; }

/* Firefox */
::-moz-placeholder {
  color: #8c8c8c; }

/* IE */
:-ms-input-placeholder {
  color: #8c8c8c; }

.fourohfour {
  height: calc(100vh - 510px);
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

