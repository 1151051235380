@import 'styles/_sass-includes.scss';

.contact {
  padding-top: 120px;
  padding-bottom: 120px;
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

.contact__form {
  padding: 0 64px 64px 64px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  // media queries
  @include breakpoint($mobile-lg) {
    padding: 0px;
  }
}

.contact__header {
  // media queries
  @include breakpoint($tablet-lg) {
    padding-top: 0;
  }

  img {
    width: calc(100% + 150px);
    max-width: none;
    position: absolute;
    right: 0;
    top: 120px;
    // media queries
    @include breakpoint($tablet-lg) {
      max-width: 100%;
      position: relative;
      width: auto;
      top: 0;
      margin: 0 auto 40px;
      display: block;
    }
  }
}

$lightGrey: rgb(240, 240, 240);
$medGrey: rgb(140,140,140);

.thanksMessage {
  padding: 0 64px 64px 64px;
  opacity: 0;
}

.contact__form {
    p {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: $lightGrey;
        border-radius: 5px;

        &.no-bg {
            background-color: transparent;
        }
    }

    .contact__label {
        width: 20%;
        font-size: 0.8em;
        color: $medGrey;

        span {
          font-size: 0.5em;
          color: $primary;
        }
    }

    .contact__input {
        width: 80%;
        background-color: $lightGrey;
    }

    .contact__textarea {
        width: 80%;
        height: 160px;
        background-color: $lightGrey;
        font-size: 0.9em;
    }

    .contact__attach {
        cursor: pointer;
    }

    select {
        cursor: pointer;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        appearance: none;
        font-size: 1em;
    }

    button {
        margin-left: -10px;
        padding: 10px 30px 10px 30px;
        background-color: $primary;
        border: 1px solid $primary; 
        border-radius: 5px;
        cursor: pointer;
        color: $white;

        &:hover {
            background-color: transparent;
            color: $primary;
            transition: 0.2s;
        }
    }

    .contact__caption {
      background: none;
      padding: 0;
    }
}

/* Chrome */
::-webkit-input-placeholder {
    color: $medGrey;
  }
  
  /* Firefox */
  ::-moz-placeholder {
    color: $medGrey;
  }
  
  /* IE */
  :-ms-input-placeholder {
    color: $medGrey;
  }