@import '~styles/_sass-includes.scss';

.home {
  position: relative;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  // media queries
  @include breakpoint($tablet-lg) {
    flex-direction: column;
    height: auto;
    position: relative;
  }
}

.circle__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 200px;
  position: absolute;
  top: 0;
  z-index: 2;
  pointer-events: none;
  max-width: 320px;
  left: 50%;
  transform: translateX(-50%);
  // media queries
  @include breakpoint($desktop-md) {
    padding-top: 75px;
  }
  @include breakpoint($tablet-lg) {
    padding-top: 100px;
  }
  @include breakpoint($tablet-md) {
    padding-top: 125px;
  }
  @include breakpoint($mobile-lg) {
    padding-top: 45px;
    max-width: 200px;
  }

  h1 {
    font-size: 70px;
    line-height: 1;
    // media  queries
    @include breakpoint($tablet-md) {
      font-size: 50px;
    }
    @include breakpoint($mobile-lg) {
      font-size: 32px;
    }

    .lang-jp & {
      font-size: 70px;
      line-height: 1;
      // media  queries
      @include breakpoint($tablet-md) {
        font-size: 50px;
      }
      @include breakpoint($mobile-lg) {
        font-size: 32px;
      }
    }
  }

  h4 {
    .lang-jp & {
      font-size: 20px;
      @include breakpoint($tablet-md) {
        font-size: 16px;
      }
      @include breakpoint($mobile-lg) {
        font-size: 14px;
      }
    }
  }

}

.herocircle {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 735px;
  height: 735px;
  // media queries
  @include breakpoint($desktop-md) {
    width: 580px;
    height: 580px;
  }
  @include breakpoint($tablet-lg) {
    width: 550px;
    height: 550px;
  }
  @include breakpoint($mobile-lg) {
    width: 323px;
    height: 323px;
  }
  @include breakpoint($mobile-xsm) {
    width: 277px;
    height: 277px;
  }

  &:not(.is-animating) {
    .circle {
      transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
      // media queries
      @include breakpoint($tablet-md, min-width) {
        &:hover {
          width: 760px !important;
          height: 760px !important;
          // media queries
          @include breakpoint($desktop-md) {
            width: 605px !important;
            height: 605px !important;
          }
          @include breakpoint($tablet-lg) {
            width: 600px !important;
            height: 600px !important;
          }
        }
      }
    }
  }

  [class^="icon-"] {
    @include font-size(h2);
    transform: translateY(130px);
    // media queries
    @include breakpoint($tablet-lg) {
      transform: translateY(80px);
    }
    @include breakpoint($mobile-lg) {
      @include font-size(h4);
      transform: translateY(30px);
    }
  }
}

.circle {
  width: 735px;
  height: 735px;
  background-color: $primary;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 1;
  cursor: pointer;
  // media queries
  @include breakpoint($desktop-md) {
    width: 580px;
    height: 580px;
  }
  @include breakpoint($tablet-lg) {
    width: 550px;
    height: 550px;
  }
  @include breakpoint($mobile-lg) {
    width: 323px;
    height: 323px;
  }
  @include breakpoint($mobile-xsm) {
    width: 277px;
    height: 277px;
  }
}

.home__wrapper {
  // media queries
  @include breakpoint($tablet-lg) {
    position: relative;
    top: -110px;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: Center;
    justify-content: center;
  }
  @include breakpoint ($mobile-lg) {
    margin-bottom: 100px;
  }
  @include breakpoint ($mobile-xsm) {
    margin-bottom: 200px;
  }
}

.home__splash {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .home__splash__logo {
    opacity: 0;
    width: 300px;
    // media queries
    @include breakpoint($mobile-md) {
      width: 200px;
    }
  }
}
