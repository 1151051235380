@import 'styles/_sass-includes.scss';

.languageselector {
  @include font(bold);
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: $primary;
  word-break: break-all;
  cursor: pointer;
  border-top: 2px solid $dark;
  padding-top: 20px;
  width: 22px;
  text-align: center;
  transition: transform 0.1s ease-in-out;
  z-index: 200;
  //media queries
  @include breakpoint($mobile-lg) {
    position: relative;
    border-color: $white;
    right: auto;
    bottom: auto;
    display: block;
  }

  @include breakpoint($mobile-lg, min-width) {
    &:hover {
      transform: scale(1.08);
    }
  }

  &.centered{
    position: relative;
    border-color: $white;
    right: auto;
    bottom: auto;
    display: block;
  }

}

.languageselector__character {
  line-height: 1.1;
  margin-top: -3px;
}
